<template>
    <div class="page sorteio">
  
      <v-row>
        <v-col cols="12" class="banner">
					<img src="../assets/sorteio.png" class="hide_mobile"></img>
					<img src="../assets/sorteio_mobile.png" class="hide_desktop"></img>
				</v-col>
      </v-row>
  
      <v-row>  
        <v-col cols="10" class="mx-auto">
        	<h1>Sorteio especial na Olimpíada Digital Senac! </h1>
          <h2>Confira abaixo todas as regras de participação e datas.</h2>
          <br/>
          <p>Esse é o nosso sorteio especial para as escolas participantes da Olimpíada Digital Senac 2024. </p>
          <p>Serão <b>3 escolas sortudas</b> que irão ao <b>Dia Teste do Rock in Rio!</b></p>
        </v-col>
      </v-row>

	  <v-row class="sorteio-info">
        <v-col cols="10" class="mx-auto">
            <h1>Ganhadores</h1>
        </v-col>
				<v-col class="info-item mx-auto" cols="10">
					<div class="img-container">
						<v-img src="../assets//cube.png" height="20px" contain></v-img>
					</div>
					<p style="font-size: 20px;">A Pequena Cruzada de Santa Therezinha do Menino Jesus</p>
				</v-col>
        
				<v-col class="info-item mx-auto" cols="10">
					<div class="img-container">
						<v-img src="../assets//cube.png" height="20px" contain></v-img>
					</div>
					<p style="font-size: 20px;">Colégio Estadual Dulce Petri</p>
				</v-col>

				<v-col class="info-item mx-auto" cols="10">
					<div class="img-container">
						<v-img src="../assets//cube.png" height="20px" contain></v-img>
					</div>
					<p style="font-size: 20px;">Colégio Estadual Deborah Mendes de Moraes</p>
				</v-col>

				<v-col class="info-item mx-auto" cols="10">
					<h2>Escolas reservas</h2>
				</v-col>

				<v-col class="info-item mx-auto" cols="10">
					<div class="img-container">
						<v-img src="../assets//cube.png" height="20px" contain></v-img>
					</div>
					<p>Escola Municipal Jornalista e Escritor Daniel Piza</p>
				</v-col>
				<v-col class="info-item mx-auto" cols="10">
					<div class="img-container">
						<v-img src="../assets//cube.png" height="20px" contain></v-img>
					</div>
					<p>Colégio Estadual Júlio Salusse</p>
				</v-col>
				<v-col class="info-item mx-auto" cols="10">
					<div class="img-container">
						<v-img src="../assets//cube.png" height="20px" contain></v-img>
					</div>
					<p>Colégio Estadual Rodrigo Otávio Filho Brasil - Itália</p>
				</v-col>
				<v-col class="info-item mx-auto" cols="10">
					<div class="img-container">
						<v-img src="../assets//cube.png" height="20px" contain></v-img>
					</div>
					<p>Colégio Estadual Prefeito Fontes Torres</p>
				</v-col>
				<v-col class="info-item mx-auto" cols="10">
					<div class="img-container">
						<v-img src="../assets//cube.png" height="20px" contain></v-img>
					</div>
					<p>Colégio Estadual André Maurois</p>
				</v-col>
				<v-col class="info-item mx-auto" cols="10">
					<div class="img-container">
						<v-img src="../assets//cube.png" height="20px" contain></v-img>
					</div>
					<p>Colégio Estadual Oscar Batista</p>
				</v-col>
				<v-col class="info-item mx-auto" cols="10">
					<div class="img-container">
						<v-img src="../assets//cube.png" height="20px" contain></v-img>
					</div>
					<p>Colégio Estadual Deputado Pedro Fernandes</p>
				</v-col>
				
				
      </v-row>


      <v-row class="sorteio-info mt-12">
        <v-col cols="10" class="mx-auto">
            <h2>Prêmios:</h2>
        </v-col>
				<v-col class="info-item mx-auto" cols="10">
					<div class="img-container">
						<v-img src="../assets//cube.png" height="20px" contain></v-img>
					</div>
					<p>Cada escola vencedora ganhará <b>6 ingressos</b> para o Dia Teste do Rock in Rio;</p>
				</v-col>
        
				<v-col class="info-item mx-auto" cols="10">
					<div class="img-container">
						<v-img src="../assets//cube.png" height="20px" contain></v-img>
					</div>
					<p><b>FastPass</b> para brinquedos no dia teste;</p>
				</v-col>

				<v-col class="info-item mx-auto" cols="10">
					<div class="img-container">
						<v-img src="../assets//cube.png" height="20px" contain></v-img>
					</div>
					<p><b>Brindes variados</b> do Rock in Rio;</p>
				</v-col>

				<v-col class="info-item mx-auto" cols="10">
					<div class="img-container">
						<v-img src="../assets//cube.png" height="20px" contain></v-img>
					</div>
					<p><b>Tour exclusivo</b> pelo evento teste.</p>
				</v-col>
      </v-row>

			<v-row class="sorteio-info mt-4">
        <v-col cols="10" class="mx-auto">
            <h2>Regras:</h2>
        </v-col>
        
				<v-col class="info-item mx-auto" cols="10">
					<div class="img-container">
						<v-img src="../assets//cube.png" height="20px" contain></v-img>
					</div>
					<p>Informamos que serão sorteadas <b>3 escolas vencedoras</b> e mais <b> 7 escolas para a fila de espera</b>. Caso as escolas vencedoras <b>não respondam nosso contato até dia 14/08/2024 às 12h</b>, seguiremos para as próximas da lista.</p>
				</v-col>
      </v-row>  

  
      <!-- Footer -->
      <Footer></Footer>
  
    </div>
  </template>
  
  <script>
  import { insertSheetItem } from "@/lib/Api";
  import { emitToastr } from "@/lib/Utils";
  import Footer from '../components/footer.vue'
  
  export default {
    name: 'SorteioView',
    components: {
      Footer
    },
    mounted() {
      window.scrollTo(0, 0)
    },
  }
  </script>
  
  <style scoped>
	h1{
    font-size: 36px;
  }

	h2{
		font-size: 22px;
	}

  p {
    margin: 0;
    padding: 0;
		font-weight: 400;
  }

  .sorteio {
    background-color: #5B268E;
    min-height: 100vh;
    color: white;
    background-image:
      radial-gradient(closest-corner circle at 80% 30%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%),
      radial-gradient(closest-corner circle at 10% 15%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%),
      radial-gradient(closest-corner circle at 5% 70%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%);
    text-align: left;
  }

  .banner {
		width: 80%;
		display: flex;
		justify-content: center;
  }

	.sorteio-info{
		display: flex;
		flex-direction: column;
	}

	.img-container{
		width: 20px;
		margin-right: 10px;
	}

	.info-item{
		display: flex;
		align-items: flex-start;
		justify-content: start;
	}
  
  </style>
  