const escolas = () => {
  return [
    {
      escola: "A Pequena Cruzada de Santa Therezinha do Menino Jesus",
      endereco: "Avenida Epitácio Pessoa, 4866",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CAIC Nações Unidas",
      endereco: "Estrada do Goiabal, s/n°",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CEI Agroecológico Barão de Langsdorff",
      endereco: "Estrada da Conceição, 4601",
      cidade: "Magé - RJ"
    },
    {
      escola: "CENP - Colegio Estadual Nilo Peçanha",
      endereco: "Rua Antonio da Silva Brinco, 1948",
      cidade: "Barra do Piraí - RJ"
    },
    {
      escola: "Centro Interescolar Miécimo da Silva",
      endereco: "Rua Augusta Candiani, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CEPAC - Colégio Estadual Pedro Álvares Cabral",
      endereco: "Rua República do Peru, 104",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIA José Francisco Lippi",
      endereco: "Estrada Teresópolis-Friburgo, km 15",
      cidade: "Teresópolis - RJ"
    },
    {
      escola: "CIEP 016 - Professora Lucilene de Souza Peclat",
      endereco: "Rua Estácio de Sá, s/nº",
      cidade: "São João de Meriti - RJ"
    },
    {
      escola: "CIEP 021 - General Osório",
      endereco: "Estrada de Adrianópolis, s/nº",
      cidade: "Nova Iguaçu - RJ"
    },
    {
      escola: "CIEP 030 - Marinheiro João Cândido",
      endereco: "Rua Wilson Lago, 266",
      cidade: "São João de Meriti - RJ"
    },
    {
      escola: "CIEP 035 - Marechal Henrique Teixeira Lott",
      endereco: "Rua Leibritz, 35",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "CIEP 048 - Djalma Maranhão",
      endereco: "Rua Elvira Ciuffo Cicarino, s/nº",
      cidade: "Itaguaí - RJ"
    },
    {
      escola: "CIEP 050 - Pablo Neruda",
      endereco: "Rodovia Amaral Peixoto, km 14, s/nº - Laranjal",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "CIEP 052 - Professora Romanda Gouveia Gonçalves",
      endereco: "Rua Veríssimo de Souza, s/nº",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "CIEP 053 - Doutor Nelson dos Santos Gonçalves",
      endereco: "Rua 1043, 205",
      cidade: "Volta Redonda - RJ"
    },
    {
      escola: "CIEP 055 - João Gregório Galindo",
      endereco: "Rodovia BR 101, km 95,5",
      cidade: "Angra dos Reis - RJ"
    },
    {
      escola: "CIEP 092 - Federico Fellini",
      endereco: "Avenida João Ribeiro, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP 098 - Professora Hilda do Carmo Siqueira",
      endereco: "Avenida São Vicente, s/nº",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "CIEP 100 - São Francisco de Assis Intercultural Brasil - França",
      endereco: "Avenida Treze de Maio, 44",
      cidade: "Mesquita - RJ"
    },
    {
      escola: "CIEP 116 - Vila Maia",
      endereco: "Avenida Nova Aurora, s/n°",
      cidade: "Belford Roxo - RJ"
    },
    {
      escola: "CIEP 118 - Vereador Vilson Campos de Macedo",
      endereco: "Avenida Governador Leonel de Moura Brizola, s/nº",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "CIEP 122 - Ermezinda Dionízio Necco",
      endereco: "Rua Vicentina Goulart, 15",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "CIEP 126 - Almendorina Azeredo",
      endereco: "Rodovia Amaral Peixoto km 148, s/nº",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "CIEP 129 - José Maria Nanci",
      endereco: "Avenida Flavio Vasconcelos, s/nº",
      cidade: "Itaboraí - RJ"
    },
    {
      escola: "CIEP 139 - Manuel Bandeira",
      endereco: "Rua Jal de Morais, s/nº",
      cidade: "São João de Meriti - RJ"
    },
    {
      escola: "CIEP 140 - Mário César Gomes da Silva",
      endereco: "Avenida Floriano Peixoto, s/nº",
      cidade: "Cachoeiras de Macacu - RJ"
    },
    {
      escola: "CIEP 141 - Vereador Said Tanus José",
      endereco: "BR 356, km 77",
      cidade: "Italva - RJ"
    },
    {
      escola: "CIEP 146 - Professor Cordelino Teixeira Paulo",
      endereco: "Rua 12 de Outubro, s/nº",
      cidade: "São Pedro da Aldeia - RJ"
    },
    {
      escola: "CIEP 148 - Professor Carlos Élio Vogas da Silva",
      endereco: "Rua Oscar Clark s/nº",
      cidade: "Araruama - RJ"
    },
    {
      escola: "CIEP 155 - Maria Joaquina de Oliveira",
      endereco: "Estrada Rio São Paulo, km 49",
      cidade: "Seropédica - RJ"
    },
    {
      escola: "CIEP 156 - Doutor Albert Sabin",
      endereco: "Antiga Rodovia Rio São Paulo, km 40",
      cidade: "Seropédica - RJ"
    },
    {
      escola: "CIEP 158 - Professora Margarida Thompsom",
      endereco: "Rua Bulhões de Carvalho, 779",
      cidade: "Piraí - RJ"
    },
    {
      escola: "CIEP 165 - Brigadeiro Sérgio Carvalho",
      endereco: "Estrada do Lameirão Pequeno,1",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP 167 - Jardim Paraíso",
      endereco: "Rua Ingá, s/nº",
      cidade: "Nova Iguaçu - RJ"
    },
    {
      escola: "CIEP 173 - Rainha Nzinga Angola",
      endereco: "Avenida Pastor Martin Luther King Jr., s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP 176 - Chico Mendes",
      endereco: "Estrada das Amendoeiras, 69",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "CIEP 178 - João Saldanha",
      endereco: "Rua Major Ênio Cavalcante Caldas, s/nº",
      cidade: "Belford Roxo - RJ"
    },
    {
      escola: "CIEP 198 - Professora Roza Ferreira de Mattos",
      endereco: "Rua Helena Passos de Souza, s/nº",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "CIEP 207 - Gilson Amado",
      endereco: "Rua Francisco Antônio Russo, 38",
      cidade: "Japeri - RJ"
    },
    {
      escola: "Ciep 208 Alceu Amoroso Lima",
      endereco: "Rua Professor Hélio Rangel, s/nº",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "CiEP 209 - Ataulfo Alves",
      endereco: "Travessa Belmonte,  s/n°",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "CIEP 223 - Olympio Marques dos Santos",
      endereco: "Estrada da Posse, s/nº",
      cidade: "Rio de Janeiro -RJ"
    },
    {
      escola: "CIEP 225 - Mário Quintana",
      endereco: "Rua Augusta Candiani, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP 226 - Porto da Estrela",
      endereco: "Rodovia Rio Mage, km 7,5",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "CIEP 229 - Cândido Portinari",
      endereco: "Avenida Castro Alves, s/nº",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "CIEP 230 - Manoel Malaquias Gurgel da Silva",
      endereco: "Rua Mario de Araujo, s/nº",
      cidade: "Nilópolis - RJ"
    },
    {
      escola: "CIEP 240 - Professor Haroldo Teixeira Valladão",
      endereco: "Rua Cristiano Otoni, s/nº",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "CIEP 244 - Oswaldo Aranha",
      endereco: "Rua Princesa Leopoldina, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP 272 - Gabriel Joaquim dos Santos",
      endereco: "Estrada dos Passageiros, s/nº",
      cidade: "São Pedro da Aldeia - RJ"
    },
    {
      escola: "CIEP 275 - Lenine Cortes Falante",
      endereco: "Rodovia RJ 152, km 1",
      cidade: "Itaocara - RJ"
    },
    {
      escola: "CIEP 276 - Ernesto Paiva",
      endereco: "Rua Doutor Virgílio Franklin, s/nº",
      cidade: "Cambuci - RJ"
    },
    {
      escola: "CIEP 277 - João Nicoláo Filho",
      endereco: "Avenida Djalma Bedda Coube, s/nº",
      cidade: "Cantagalo - RJ"
    },
    {
      escola: "CIEP 278 - Joaquim Osório Duque Estrada",
      endereco: "Avenida Paschoal Carlos Magno",
      cidade: "Paty do Alferes - RJ"
    },
    {
      escola: "CIEP 280 - Professor Vasco Fernandes da Silva Porto",
      endereco: "Rua Idimar Policiando da Silva, s/nº",
      cidade: "Carmo - RJ"
    },
    {
      escola: "CIEP 281 - Gabriela Mistral",
      endereco: "Estrada União Indústria, s/nº",
      cidade: "Petrópolis - RJ"
    },
    {
      escola: "CIEP 283 - Maria Amélia Pacheco",
      endereco: "Rua José Muniz De Andrade, 663",
      cidade: "Sumidouro - RJ"
    },
    {
      escola: "CIEP 285 - Luiz José Daflon Gomes",
      endereco: "Avenida Paulino Fernandes da Silva, 409",
      cidade: "Sapucaia - RJ"
    },
    {
      escola: "CIEP 286 - Murilo Portugal",
      endereco: "Rua 19 A, s/nº",
      cidade: "Barra do Piraí - RJ"
    },
    {
      escola: "CIEP 287 -  Professora Angelina Teixeira Netto Sym",
      endereco: "Rua José Alves Pimenta, 1250",
      cidade: "Barra do Piraí - RJ"
    },
    {
      escola: "CIEP 291 - Dom Martinho Schlude",
      endereco: "Rua Benedito Honorato, 147",
      cidade: "Pinheiral - RJ"
    },
    {
      escola: "CIEP 293 - Walmir de Freitas Monteiro",
      endereco: "Avenida Nossa Senhora do Amparo, 4877",
      cidade: "Volta Redonda - RJ"
    },
    {
      escola: "CIEP 296 - Presidente Benes",
      endereco: "Rodovia Saturmino Braga, s/nº",
      cidade: "Rio Claro - RJ"
    },
    {
      escola: "CIEP 298 - Manual Duarte",
      endereco: "Rua Nestor de Oliveira Barbosa, s/nº",
      cidade: "Rio das Flores - RJ"
    },
    {
      escola: "CIEP 299 - Jiúlio Caruso",
      endereco: "Rua Bartolomeu Bueno da Ribeira, s/nº",
      cidade: "Volta Redonda - RJ"
    },
    {
      escola: "CIEP 303 - Ayrton Senna da Silva",
      endereco: "Autoestrada Engenheiro Fernando Mac Dowell, 15",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP 305 - Heitor dos Prazeres",
      endereco: "Estrada da Matriz, s/nº",
      cidade: "Rio de janeiro - RJ"
    },
    {
      escola: "CIEP 306 - Deputado David Quindere",
      endereco: "Rua Geraldo Martins Santana, 3055",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "CIEP 308 - Pascoal Carlos Magno",
      endereco: "Avenida Américo da Costa Cardoso, s/nº",
      cidade: "Itaboraí - RJ"
    },
    {
      escola: "CIEP 310 - Professora Alice Aiex",
      endereco: "Rua José Bento de Oliveira, 1005",
      cidade: "Barra do Piraí - RJ"
    },
    {
      escola: "Ciep 311 - Deputado Bocayuva Cunha",
      endereco: "Estrada Santa Eugênia, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP 312 - Raul Ryff",
      endereco: "Estrada dos Vieiras, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP 313 - Rubem Braga",
      endereco: "Estrada do Taquaral, 111",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP 316 - General Ladário Pereira Telles",
      endereco: "Rua Mauá, s/nº",
      cidade: "Belford Roxo - RJ"
    },
    {
      escola: "CIEP 317 - Aurélio Buarque de Holanda",
      endereco: "Rua Dona Vitalina, s/nº",
      cidade: "Nova Iguaçu - RJ"
    },
    {
      escola: "CIEP 323 - Maria Werneck de Castro",
      endereco: "Estrada Pedro Borges de Freitas, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP 324 - Mahatma Gandhi",
      endereco: "Rua Flávia, s/nº",
      cidade: "Nova Iguaçu - RJ"
    },
    {
      escola: "CIEP 333 - Cacilda Becker",
      endereco: "Estrada da Grama, s/nº",
      cidade: "Nova Iguaçu - RJ"
    },
    {
      escola: "CIEP 336 - Octavio Malta",
      endereco: "Rue Frei Timóteo, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP 337 - Berta Lutz",
      endereco: "Rua Paconé, s/nº",
      cidade: "Belford Roxo - RJ"
    },
    {
      escola: "CIEP 339 - Mário Tamborindeguy",
      endereco: "Estrada Pedro Borges de Freitas, s/nº",
      cidade: "Irajá - RJ"
    },
    {
      escola: "CIEP 340 - Professora Laís Martins",
      endereco: "Estrada Velha do Pilar, s/nº",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "CIEP 347 - Doutor Jorge Miguel Jayme",
      endereco: "Sítio Bom Destino, s/nº",
      cidade: "Resende - RJ"
    },
    {
      escola: "CIEP 351 - Ministro Salgado Filho",
      endereco: "Estrada Santa Bárbara, s/nº",
      cidade: "Nova Iguaçu - RJ"
    },
    {
      escola: "CIEP 352 - Senador Severo Gomes",
      endereco: "Rua Francisco Barone, s/nº",
      cidade: "Nova Iguaçu - RJ"
    },
    {
      escola: "CIEP 355 - Roquete Pinto",
      endereco: "Estrada do Rio D'ouro, s/nº",
      cidade: "Queimados - RJ"
    },
    {
      escola: "CIEP 362 - Roberto Burle Marx",
      endereco: "Estrada da Ilha de Guaratiba, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP 368 - João Conceição Canuto",
      endereco: "Rua Professor Chico, s/nº",
      cidade: "Itaguaí - RJ"
    },
    {
      escola: "CIEP 379 - Raul Seixas",
      endereco: "Estrada do Camboatá, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP 382 - Aspirante Francisco Mega",
      endereco: "Rua Salustiano Silva, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP 386 - Guilherme da Silveira Filho",
      endereco: "Rua Do Limão - Conjunto João Saldanha, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP 390 - Chão de Estrelas",
      endereco: "Rua Sete de Setembro, s/nº",
      cidade: "Nova Iguaçu - RJ"
    },
    {
      escola: "CIEP 392 - Mário de Andrade",
      endereco: "Estrada Vítor Dumas, 2567",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP 398 - Mário Lima",
      endereco: "Rua Cláudio, s/nº",
      cidade: "São João de Meriti - RJ"
    },
    {
      escola: "CIEP 399 - Jean Baptiste Debret",
      endereco: "Rua Álvaro Proença, s/nº",
      cidade: "São João de Meriti - RJ"
    },
    {
      escola: "CIEP 412 - Doutor Zerbini",
      endereco: "Rodovia Amaral Peixoto, km 11, s/nº",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "CIEP 415 - Miguel de Cervantes",
      endereco: "Rodovia BR 493, km 1",
      cidade: "Itaboraí - RJ"
    },
    {
      escola: "CIEP 417 - José do Patrocínio",
      endereco: "Rua Dom Aquino Correa, s/nº",
      cidade: "Campos dos Goytacazes - RJ"
    },
    {
      escola: "CIEP 418 - Antônio Carlos Bernardes-Mussum",
      endereco: "Rua Morais Pinheiro, 837",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP 424 - Pedro Amorim",
      endereco: "Rua Manoel Morel Peçanha, s/nº",
      cidade: "Itaboraí - RJ"
    },
    {
      escola: "CIEP 432 - Alberto Cavalcanti",
      endereco: "Rua Florestal, s/n⁰",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP 433 - Togo Renan Soares Kanela",
      endereco: "Rua Tamarana, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP 435 - Hélio Pellegrino",
      endereco: "Estrada Guandu do Sapê, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP 440 - Major Joaquim de Azevedo Coutinho",
      endereco: "Rua Mário José Francisco, s/nº",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "CIEP 444 - Israel Jacob Averback",
      endereco: "Avenida do Canal, s/nº",
      cidade: "Magé - RJ"
    },
    {
      escola: "CIEP 451 - Eliza Antonia Rainho Dias",
      endereco: "Rua Augusta de Jesus, s/nº",
      cidade: "Itaboraí - RJ"
    },
    {
      escola: "CIEP 453 - Doutor Milton Rodrigues Rocha",
      endereco: "Avenida Prefeito Milton Rodrigues da Rocha, 40",
      cidade: "Itaboraí - RJ"
    },
    {
      escola: "CIEP 460 - Thióphyla Bragança",
      endereco: "Rua Marques de Paraná, s/nº",
      cidade: "Araruama - RJ"
    },
    {
      escola: "CIEP 461 - Clóvis Tavares",
      endereco: "Rua Alan Kardec, s/n°",
      cidade: "Campos dos Goytacazes - RJ"
    },
    {
      escola: "CIEP 468 - Olga Thurler Mendonça da Fonseca",
      endereco: "Rua Celina Garcia da Fonseca",
      cidade: "Natividade - RJ"
    },
    {
      escola: "CIEP 476 - Elias Lazaroni",
      endereco: "Avenida A, s/nº",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "CIEP 494 - Alexandre Carvalho",
      endereco: "Avenida César Lattes, s/nº",
      cidade: "Miguel Pereira - RJ"
    },
    {
      escola: "CIEP 498 - Irmã Dulce",
      endereco: "Rua Pastor Antônio Antunes da Rocha, 1",
      cidade: "Itaguaí - RJ"
    },
    {
      escola: "CIEP 500 - Antônio Botelho",
      endereco: "Estrada RJ 127 s/nº",
      cidade: "Paracambi - RJ"
    },
    {
      escola: "CIEP Armindo Marcílio Doutel de Andrade",
      endereco: "Rua Flávio Fraga, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP Brizolão 037 - Ernesto Guevara",
      endereco: "Rua Olavo Batista, s/nº",
      cidade: "Belford Roxo - RJ"
    },
    {
      escola: "CIEP Brizolão 130 - Doutor Elias de Miranda Saraiva",
      endereco: "Avenida Vinte e Dois de Maio, s/nº",
      cidade: "Itaboraí - RJ"
    },
    {
      escola: "CIEP Brizolão 133 - Oswaldo José Lourenço",
      endereco: "Rua Lourenço Campos, s/nº",
      cidade: "São João de Meriti - RJ"
    },
    {
      escola: "CIEP Brizolão 145 - Doutor Oswaldo Cruz",
      endereco: "Rua Camilo Teixeira, 2",
      cidade: "Cordeiro - RJ"
    },
    {
      escola: "CIEP Brizolão 183 - João Vitta",
      endereco: "Avenida do Canal Três, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP Brizolão 193 - Wilson Mendes",
      endereco: "Rua Samuel Bessa, s/nº",
      cidade: "Cabo frio - RJ"
    },
    {
      escola: "CIEP Brizolão 252 - João Baptista Caffaro",
      endereco: "Rua Primeiro de Janeiro, s/nº",
      cidade: "Tanguá - RJ"
    },
    {
      escola: "CIEP Brizolão 322 - Mozart Cunha Guimarães",
      endereco: "Rua Manoel Vieira de Aguiar, s/nº",
      cidade: "Bom Jardim - RJ"
    },
    {
      escola: "CIEP Brizolão 329 - Juan Martinho Carrasco",
      endereco: "Estrada do Mazomba, 1",
      cidade: "Itaguaí - RJ"
    },
    {
      escola: "CIEP Brizolão 348 - Eugênia Moreyra",
      endereco: "Rua F com a Rua A, s/nº",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "CIEP Brizolão 377 - Carmem da Silva",
      endereco: "Caminho das Mulheres, s/nº",
      cidade: "Belford Roxo - RJ"
    },
    {
      escola: "CIEP Brizolão 397 - Paulo pontes",
      endereco: "Rua Tenente Pedro Gomes Martins, s/nº",
      cidade: "São João de Meriti - RJ"
    },
    {
      escola: "CIEP Brizolão 441 - Mané Garrincha",
      endereco: "Rua José Pereira de Souza, s/nº",
      cidade: "Magé - RJ"
    },
    {
      escola: "CIEP Cláudio Manoel da Costa",
      endereco: "Rua da Cidra, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP Doutor Ernesto Che Guevara",
      endereco: "Rua Timbaúba, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "CIEP Professor Darcy Ribeiro",
      endereco: "Rua das Conchas, S/N",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Acari",
      endereco: "Rua Fausto e Castro, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Adalgisa Monteiro",
      endereco: "Estrada de Jacarepaguá, 3145",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Adlai Stevenson",
      endereco: "Rua Heron Domingues, 122",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Admardo Alves Torres",
      endereco: "Rua XVI c/ Rua I, s/ n°",
      cidade: "São João da Barra - RJ"
    },
    {
      escola: "Colégio Estadual Agrícola Professor Luiz Paulo Rodrigues",
      endereco: "BR 356 km3 Rodovia Itaperuna/Muriaé",
      cidade: "Itaperuna - RJ"
    },
    {
      escola: "Colégio Estadual Agripino Grieco",
      endereco: "Rua Monsenhor Jerônimo, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colegio Estadual Alba Canizares do Nascimento",
      endereco: "Rua Professor Souza Moreira, 301",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Alcindo Guanabara",
      endereco: "Rua Joaquim Coelho, 139",
      cidade: "Guapimirim - RJ"
    },
    {
      escola: "Colégio Estadual Aldebarã",
      endereco: "Avenida Antares, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Alfredo Pujol",
      endereco: "Rua Washington Luiz, 330",
      cidade: "Rio Claro - RJ"
    },
    {
      escola: "Colégio Estadual Alice de Souza Bruno",
      endereco: "Rua Osvaldo Dantas Martins, 32",
      cidade: "Seropédica - RJ"
    },
    {
      escola: "Colégio Estadual Almirante Álvaro Alberto",
      endereco: "Praça da República, s/nº",
      cidade: "Paraty - RJ"
    },
    {
      escola: "Colegio Estadual Almirante Barroso",
      endereco: "Avenida Guilherme Morrisson, s/nº",
      cidade: "Campos Dos Goytacazes - RJ"
    },
    {
      escola: "Colégio Estadual Almirante Frederico Villar",
      endereco: "Praça da Bandeira, 13",
      cidade: "Arraial do Cabo - RJ"
    },
    {
      escola: "Colégio Estadual Almirante Frontin",
      endereco: "Rua Nova Aliança, s/n°",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Almirante Protógenes",
      endereco: "Avenida Getúlio Vargas, 88",
      cidade: "Duas Barras - RJ"
    },
    {
      escola: "Colégio Estadual Almirante Rodrigues Silva",
      endereco: "Rua Allan Kardec, 254",
      cidade: "Valença - RJ"
    },
    {
      escola: "Colégio Estadual Alvarina de Carvalho Jannotti",
      endereco: "Rua Vereador Jaime de Azevedo s/nº",
      cidade: "Seropédica - RJ"
    },
    {
      escola: "Colégio Estadual Amanda Velasco",
      endereco: "Estrada de Ipiiba, 151",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "Colégio Estadual Amazonas",
      endereco: "Rua Guarujá, 1900",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual André Maurois",
      endereco: "Avenida Visconde de Albuquerque, 1325",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Anita Garibaldi",
      endereco: "Estrada Maracajas, 1294",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Antonina Ramos Freire",
      endereco: "Rua José Carlos Giovani, s/nº",
      cidade: "Resende - RJ"
    },
    {
      escola: "Colégio Estadual Antonio Pecly",
      endereco: "Rua Coronel José Olímpia de Carvalho, 722",
      cidade: "Cordeiro - RJ"
    },
    {
      escola: "Colégio Estadual Antônio Pinto de Moraes",
      endereco: "Rodovia RJ 124 km 25",
      cidade: "Araruama"
    },
    {
      escola: "Colégio Estadual Antônio Quirino",
      endereco: "Avenida Wencesláu Brás, s/n°",
      cidade: "Resende - RJ"
    },
    {
      escola: "Colégio Estadual Armando Dias Brasil- Argentina",
      endereco: "Rua Edna Palatinik, s/nº",
      cidade: "Japeri - RJ"
    },
    {
      escola: "Colégio Estadual Augusto Spinelli",
      endereco: "Praça Santana - Cônego",
      cidade: "Nova Friburgo - RJ"
    },
    {
      escola: "Colégio Estadual Baldomero Barbará",
      endereco: "Rua Doutor Robert Lang, 106",
      cidade: "Barra Mansa - RJ"
    },
    {
      escola: "Colégio Estadual Baltazar Bernardino",
      endereco: "Rua Mariz e Barros, 444",
      cidade: "Niterói - RJ"
    },
    {
      escola: "Colégio Estadual Bananal",
      endereco: "Rodovia Presidente Dutra, km 37",
      cidade: "Seropédica - RJ"
    },
    {
      escola: "Colégio Estadual Bangu",
      endereco: "Estrada do Engenho, 442",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Barão de Aiuruoca",
      endereco: "Rua Professor Pedro Vaz, 8",
      cidade: "Barra Mansa - RJ"
    },
    {
      escola: "Colégio Estadual Barão de Itararé",
      endereco: "Rua Vitor Dumas, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Barão de Juparanã",
      endereco: "Rua Barão de Santa Mônica, 305",
      cidade: "Valença - RJ"
    },
    {
      escola: "Colégio Estadual Barão de Mauá",
      endereco: "Rua dos Engenheiros, s/nº",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "Colégio Estadual Barão de Palmeiras",
      endereco: "Estrada José Romero Gamboa, s/nº",
      cidade: "Paraíba do Sul - RJ"
    },
    {
      escola: "Colégio Estadual Barão de Santa Margarida",
      endereco: "Rua Coremas, 358",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Barão de Santa Maria Madalena",
      endereco: "Rua Gwyer de Azevedo, n° 10",
      cidade: "Santa Maria Madalena - RJ"
    },
    {
      escola: "Colégio Estadual Barão de Tefé",
      endereco: "Estrada Rio São Paulo km 39,5",
      cidade: "Seropédica - RJ"
    },
    {
      escola: "Colégio Estadual Barão do Rio Branco",
      endereco: "Rua do Matadouro, 25",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Baronesa de Saavedra",
      endereco: "Rua Marechal Falcão da Fronta, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Bélgica",
      endereco: "Rua Francolim, 50",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Benta Pereira",
      endereco: "Rua Antônio Neves, 148",
      cidade: "Campos dos Goytacazes - RJ"
    },
    {
      escola: "Colégio Estadual Bernardino da Costa Lopes",
      endereco: "Rua Alexandre Pereira dos Santos, s/nº",
      cidade: "Rio Bonito - RJ"
    },
    {
      escola: "Colégio Estadual Bernardino Mello Júnior",
      endereco: "Rua Professor Joaquim Cardoso de Matos, 317",
      cidade: "Nova Iguaçu - RJ"
    },
    {
      escola: "Colégio Estadual Bernardo de Vasconelos",
      endereco: "Praça Santa Rosália,100",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Bezerra de Menezes",
      endereco: "Praça Antônio José Miranda de Carvalho, 248",
      cidade: "Paraíba do Sul - RJ"
    },
    {
      escola: "Colégio Estadual Brigadeiro Castrioto Brasil-Espanha",
      endereco: "Rua Profesora Emylce, s/nº",
      cidade: "Niterói - RJ"
    },
    {
      escola: "Colégio Estadual Caio Francisco de Figueiredo",
      endereco: "Rua Caio Francisco de Figueiredo, s/nº",
      cidade: "Maricá - RJ"
    },
    {
      escola: "Colégio Estadual Cardoso Fontes",
      endereco: "Rua Bingen, 210",
      cidade: "Petrópolis - RJ"
    },
    {
      escola: "Colégio Estadual Carlos Arnoldo Abruzzinni da Fonseca",
      endereco: "Estrada de Vasconcelos, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Carlos Magno Nazareth Cerqueira",
      endereco: "Rua Pedro Osório, 360",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Carmem de Luca Andreiolo",
      endereco: "Rua Major Mário Lamartine, 44",
      cidade: "Miguel Pereira - RJ"
    },
    {
      escola: "Colégio Estadual Casimiro de Abreu",
      endereco: "Praça Feliciano Sodré, 286",
      cidade: "Casimiro de Abreu - RJ"
    },
    {
      escola: "Colégio Estadual Castelnuovo",
      endereco: "Rua Francisco Otaviano, 105",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Célio Barbosa Anchite",
      endereco: "Rua Amazonas, 233",
      cidade: "Pinheiral - RJ"
    },
    {
      escola: "Colégio Estadual Central do Brasil",
      endereco: "Rua Rio Grande do Sul, 49",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Charles Dickens",
      endereco: "Rua Primeira Cruz, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Chequer Jorge",
      endereco: "Avenida Euclídes Poubel de Lima, 341",
      cidade: "Itaperuna - RJ"
    },
    {
      escola: "Colégio Estadual Chico Anysio",
      endereco: "Rua Amaral, 26",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Chiquinha Gonzaga",
      endereco: "Praça Soldado Geraldo da Cruz, 50",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Cizínio Soares Pinto",
      endereco: "Avenida Presidente Roosevelt, 2",
      cidade: "Niterói - RJ"
    },
    {
      escola: "Colégio Estadual Clodomiro Vasconcelos",
      endereco: "Rua Gorgênio de Freitas Marins, 102",
      cidade: "Itaguaí - RJ"
    },
    {
      escola: "Colégio Estadual Coelho Neto",
      endereco: "Rua Umbuzeiro, 455",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Collecchio",
      endereco: "Rua Francisco Barreto, 829",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Comendador Soares",
      endereco: "Rua Milton Silva, 120",
      cidade: "Nova Iguaçu - RJ"
    },
    {
      escola: "Colégio Estadual Compositor Luiz Carlos da Vila",
      endereco: "Avenida Dom Helder Câmara, 1184",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Compositor Manaceia José de Andrade",
      endereco: "Rua Pereira da Costa, 101",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Conde Pereira Carneiro - Angra dos Reis",
      endereco: "Rua Doutor Álvaro Pessoa, s/nº",
      cidade: "Angra dos Reis - RJ"
    },
    {
      escola: "Colégio Estadual Conde Pereira Carneiro - Rio de Janeiro",
      endereco: "Rua Molière, 10",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Coronel Camisão",
      endereco: "Avenida Darcy Vargas , 1",
      cidade: "Piraí - RJ"
    },
    {
      escola: "Colégio Estadual Coronel Francisco Lima",
      endereco: "Rua Visconde de Itauna, s/ n°",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "Colégio Estadual Coronel PM Marcus Jardim",
      endereco: "Rua Custódio de Oliveira, s/nº",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "Colégio Estadual Coronel Sérgio José do Amaral",
      endereco: "Rua João Félix do Bonfim, s/nº",
      cidade: "Magé - RJ"
    },
    {
      escola: "Colégio Estadual Cristóvão Colombo",
      endereco: "Avenida Santa Cruz, 4725",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Cyro Monteiro",
      endereco: "Rua Do Antúrio, 31",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Dalva de Oliveira",
      endereco: "Estrada Manoel Nogueira de Sá, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual David Capistrano",
      endereco: "Rua Professora Maria Mendonça Peixoto, s/nº",
      cidade: "Niterói - RJ"
    },
    {
      escola: "Colégio Estadual de São Fidélis",
      endereco: "Praça Teixeira Soares, 20",
      cidade: "São Fidélis - RJ"
    },
    {
      escola: "Colégio Estadual Deborah Mendes de Moraes",
      endereco: "Rua Belchior da Fonseca, 643",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Deputado Pedro Fernandes",
      endereco: "Rua Debussy, 148",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Desembargador José Augusto Coelho da Rocha Junior",
      endereco: "Avenida Manuel Duarte, 1980",
      cidade: "Rio Bonito - RJ"
    },
    {
      escola: "Colégio Estadual Dom Hélder Câmara",
      endereco: "Rua Henrique Scheid, 580",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Dom João VI",
      endereco: "Rua Jorge Ferreira da Silva, 91",
      cidade: "Queimados - RJ"
    },
    {
      escola: "Colégio Estadual Doutor Albert Sabin",
      endereco: "Rua Tenente Ronald Santoro, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Doutor Antônio Fernandes",
      endereco: "Rua Bonifácio Portela, 22",
      cidade: "Miguel Pereira - RJ"
    },
    {
      escola: "Colégio Estadual Doutor Armando Sá Couto",
      endereco: "Estrada da Campanha, s/n°",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "Colégio Estadual Doutor Artur Vargas",
      endereco: "Rua Coronel Carvalho, 230",
      cidade: "Angra dos Reis - RJ"
    },
    {
      escola: "Colégio Estadual Doutor Feliciano Sodré",
      endereco: "Rua Duque de Caxias, 78",
      cidade: "São Pedro da Aldeia - RJ"
    },
    {
      escola: "Colégio Estadual Doutor Humberto Soeiro de Carvalho",
      endereco: "Rua Maria do Nascimento, s/nº",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "Colégio Estadual Doutor João Gomes de Mattos Sobrinho",
      endereco: "Rua Apolônio Elías da Cruz, s/nº",
      cidade: "Maricá - RJ"
    },
    {
      escola: "Colégio Estadual Doutor João Maia",
      endereco: "Praça Doutor Oliveira Botelho, s/nº",
      cidade: "Resende - RJ"
    },
    {
      escola: "Colégio Estadual Doutor José Pereira Pinto",
      endereco: "Avenida das Estrelas, 679",
      cidade: "Campos dos Goytacazes - RJ"
    },
    {
      escola: "Colégio Estadual Doutor Luciano Pestre",
      endereco: "Avenida Pastor José Gomes de Souza, s/nº",
      cidade: "Niterói - RJ"
    },
    {
      escola: "Colégio Estadual Doutor Moacyr Meirelles Padilha",
      endereco: "Rodovia Amaral Peixoto, km 19",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "Colégio Estadual Doutor Oscar Pimenta Soares",
      endereco: "Rua Rosas, s/nº",
      cidade: "São João de Meriti - RJ"
    },
    {
      escola: "Colégio Estadual Doutor Oswaldo Terra",
      endereco: "Rua Waldemar Ferreira Cristiano, 497",
      cidade: "Valença - RJ"
    },
    {
      escola: "Colégio Estadual Doutor Péricles Corrêa da Rocha",
      endereco: "Rua Alcides Lima, 36",
      cidade: "Bom Jardim - RJ"
    },
    {
      escola: "Colégio Estadual Doutor Sylvio Bastos Tavares",
      endereco: "Rua Viveiros de Vasconcelos, 245",
      cidade: "Campos dos Goytacazes - RJ"
    },
    {
      escola: "Colégio Estadual Doutor Thiers Cardoso",
      endereco: "Avenida Tarcísio Miranda, 30",
      cidade: "Campos dos Goytacazes - RJ"
    },
    {
      escola: "Colégio Estadual Doutor Tuffy El Jaick",
      endereco: "Rua São Pedro, s/nº",
      cidade: "Nova Friburgo - RJ"
    },
    {
      escola: "Colégio Estadual Doutor Valmir Peçanha",
      endereco: "Rua Professor Moreira, 990 - confirmar",
      cidade: "Três Rios - RJ"
    },
    {
      escola: "Colégio Estadual Dulce Petri",
      endereco: "Rua Florianópolis, 75",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "Colégio Estadual Duque Costa",
      endereco: "Rua Joana Kalil, 994",
      cidade: "São João de Meriti - RJ"
    },
    {
      escola: "Colégio Estadual Duque de Caxias",
      endereco: "Rua Maria Luiza Reis, 645",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "Colégio Estadual Eduardo Breder",
      endereco: "Estrada Teresópolis-Friburgo",
      cidade: "Nova Friburgo - RJ"
    },
    {
      escola: "Colégio Estadual Eduardo Mondlane",
      endereco: "Rua Acapori, 495",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Elisiário Matta",
      endereco: "Rua Abreu Rangel, 115",
      cidade: "Maricá - RJ"
    },
    {
      escola: "Colégio Estadual Embaixador José Bonifácio",
      endereco: "Rua Barros Franco, s/nº",
      cidade: "Petrópolis - RJ"
    },
    {
      escola: "Colégio Estadual Embaixador Raul Fernandes",
      endereco: "Rua Riodades, 253",
      cidade: "Niterói - RJ"
    },
    {
      escola: "Colégio Estadual Emília Roschemant",
      endereco: "Rua Herminia dos Santos Silva , s/nº",
      cidade: "Nova Friburgo - RJ"
    },
    {
      escola: "Colégio Estadual Engenheiro João Thomé",
      endereco: "Rua Cabo José da Conceição, 135",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Ercilia Muylaert de Menezes",
      endereco: "Rua Capitão de Almeida, s/nº",
      cidade: "São Francisco de Itabapoana - RJ"
    },
    {
      escola: "Colégio Estadual Erich Walter Heine",
      endereco: "Rua Manoel Lourenço dos Santos, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Estefânia Pereira Pinto",
      endereco: "Rua A , 65",
      cidade: "Campos dos Goytacazes - RJ"
    },
    {
      escola: "Colégio Estadual Etelvina Alves da Silva",
      endereco: "Rua São Domingos, 54",
      cidade: "Itaperuna - RJ"
    },
    {
      escola: "Colegio Estadual Etelvina Schottz",
      endereco: "Estrada Friburgo Teresopolis, km 3",
      cidade: "Nova Friburgo - RJ"
    },
    {
      escola: "Colégio Estadual Francesca Carey",
      endereco: "Praça Nuno Duarte, s/nº",
      cidade: "Itaboraí - RJ"
    },
    {
      escola: "Colégio Estadual Francisco Caldeira de Alvarenga",
      endereco: "Rua Issami Nakamura Okamo, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Francisco José do Nascimento",
      endereco: "Rua Simão Luiz Da Costa, Estrada de Ponta Negra, s/nº",
      cidade: "Maricá - RJ"
    },
    {
      escola: "Colégio Estadual Francisco Portella",
      endereco: "Rua Presidente Getúlio Vargas, 8",
      cidade: "Natividade - RJ"
    },
    {
      escola: "Colégio Estadual Frederico Azevedo",
      endereco: "Rua Raul Mesquita, s/nº",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "Colégio Estadual Frei Henrique de Coimbra",
      endereco: "Avenida Demétrio Ribeiro, s/nº",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "Colégio Estadual Frei Tomás",
      endereco: "Avenida Roberto Silveira, 427",
      cidade: "Itaocara - RJ"
    },
    {
      escola: "Colégio Estadual Freire Allemao - CEFA",
      endereco: "Avenida Cesário de Melo, 4180",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual George Washington",
      endereco: "Rua Alvorada, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Getúlio Vargas",
      endereco: "Avenida Francisco Faleiro de Freitas Lima, 789",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "Colégio Estadual Hebe Camargo",
      endereco: "Rua Projetada 1, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Heitor Lira",
      endereco: "Rua Cuba, 320",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Hélio Rangel",
      endereco: "Rua Odessa, 546",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "Colégio Estadual Herbert Moses",
      endereco: "Av Chopin, 25",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "Colégio Estadual Hermínia de Oliveira Mattos",
      endereco: "Alameda Waldomiro José Nogueira, s/nº",
      cidade: "Mangaratiba - RJ"
    },
    {
      escola: "Colégio Estadual Higino da Silveira",
      endereco: "Avenida Delfim Moreira, 1115",
      cidade: "Teresópolis - RJ"
    },
    {
      escola: "Colégio Estadual Hilário Ribeiro",
      endereco: "Alameda São Boaventura, 794",
      cidade: "Niterói - RJ"
    },
    {
      escola: "Colégio Estadual Hispano Brasileiro João Cabral de Melo Neto",
      endereco: "Rua Venceslau, 225",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Honório Lima",
      endereco: "Rua Almirante Machado Portela, 27",
      cidade: "Angra dos Reis - RJ"
    },
    {
      escola: "Colégio Estadual Ignácio Azevedo do Amaral",
      endereco: "Rua Jardim Botânico, 563",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Infante Dom Henrique",
      endereco: "Rua Belfort Roxo, 433",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Iracema Leite Nader",
      endereco: "Rua Claudionor Cabral Franco, 2237",
      cidade: "Barra Mansa - RJ"
    },
    {
      escola: "Colégio Estadual Irineu José Ferreira",
      endereco: "Av. Cesário de Melo, 1802",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Ismael Branco",
      endereco: "Rua Rual Lengruber, s/nº",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "Colégio Estadual Itália",
      endereco: "Rua Marupiara, 105",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Jaime Queiroz de Souza",
      endereco: "Rua Pereira Marins, 187",
      cidade: "Itaocara - RJ"
    },
    {
      escola: "Colégio Estadual Januário de Toledo Pizza",
      endereco: "Rua Amaral Peixoto, 234",
      cidade: "São Sebastião do Alto - RJ"
    },
    {
      escola: "Colégio Estadual Jayme Silvestre Camargo",
      endereco: "Rua Jayme Camargo, 49",
      cidade: "Barra Mansa - RJ"
    },
    {
      escola: "Colégio Estadual João Alfredo",
      endereco: "Boulevard 28 de Setembro, 109",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual João Cardoso Intercultural Brasil - Argentina",
      endereco: "Rua Benjamin Constant, 295",
      cidade: "Nilópolis - RJ"
    },
    {
      escola: "Colégio Estadual João de Oliveira Botas",
      endereco: "Avenida José Bento Ribeiro Dantas,1084",
      cidade: "Búzios - RJ"
    },
    {
      escola: "Colégio Estadual João Kopke",
      endereco: "Praça Roger Malhardes, 7",
      cidade: "Engenheiro Paulo de Frontin - RJ"
    },
    {
      escola: "Colégio Estadual João Marques dos Reis",
      endereco: "Praça Saica, 57",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual João Paulo II",
      endereco: "Rua Joaquim Cardoso da Cruz, s/nº",
      cidade: "Mangaratiba - RJ"
    },
    {
      escola: "Colégio Estadual João Proença",
      endereco: "Rua Costa Nunes, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual João Salim Miguel",
      endereco: "Rua da Chita, 419",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual João Santos Souto",
      endereco: "Rua Indígena, s/nº",
      cidade: "Japeri - RJ"
    },
    {
      escola: "Colégio Estadual Joaquim Távora",
      endereco: "Praça Prefeito Ferraz, s/nº",
      cidade: "Niterói - RJ"
    },
    {
      escola: "Colégio Estadual Johenir Henriques Viégas",
      endereco: "Rua Nilo Peçanha, s/nº",
      cidade: "Itaocara - RJ"
    },
    {
      escola: "Colégio Estadual Jorge Miguel Salin",
      endereco: "Rua da Chita, 419",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Jorge Zarur",
      endereco: "Rua Edmo Zarife, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Jornalista Artur da Távola",
      endereco: "Praça Igara, 40",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Jornalista Rodolfo Fernandes",
      endereco: "Rua Leonardo Aníbal Tonine, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Jornalista Tim Lopes",
      endereco: "Estrada de Itararé, 690",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual José Bonifácio",
      endereco: "Rua Doutor Carlos Maximiano, s/nº",
      cidade: "Niterói - RJ"
    },
    {
      escola: "Colégio Estadual José Cardoso de Moraes",
      endereco: "Rua Santa Luzia, 75",
      cidade: "São Sebastião do Alto - RJ"
    },
    {
      escola: "Colégio Estadual José de Lannes Dantas Brandão",
      endereco: "Rua Governador Chagas Freitas, s/nº",
      cidade: "Porciúncula - RJ"
    },
    {
      escola: "Colégio Estadual José Eduardo de Macedo Soares",
      endereco: "Rua Mário Piragibe, 41",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual José Leite Lopes - NAVE",
      endereco: "Rua Uruguai, 204",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual José Maria de Brito Brasil - Japão",
      endereco: "Estrada Deputado Octávio Cabral, 668, Jardim América",
      cidade: "Itaguaí - RJ"
    },
    {
      escola: "Colégio Estadual Jose Marti",
      endereco: "Avenida Professor Plínio Bastos, 631",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual José Matoso Maia Forte",
      endereco: "Rua João Marques Ferreira, 80",
      cidade: "Rio Bonito - RJ"
    },
    {
      escola: "Colégio Estadual José Veríssimo",
      endereco: "Avenida Duque de Caxias, 627",
      cidade: "Magé - RJ"
    },
    {
      escola: "Colégio Estadual Josue de Castro",
      endereco: "Rua Soldado Servino Mengarda, 260",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Júlio Salusse",
      endereco: "Rua Izelino Maduro, s/nº",
      cidade: "Nova Friburgo - RJ"
    },
    {
      escola: "Colégio Estadual Lauro Corrêa",
      endereco: "Rua Macaé, s/nº",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "Colégio Estadual Lauro Sodré",
      endereco: "Rua Darke de Mattos, 166",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Leôncio Pereira Gomes",
      endereco: "Rua Rodrigues Peixoto, 1",
      cidade: "Campos dos Goytacazes - RJ"
    },
    {
      escola: "Colégio Estadual Leonel Azevedo",
      endereco: "Rua Luís Sá, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Leopoldina da Silveira",
      endereco: "Rua da Feira, 77",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Leopoldo Oscar Stutz",
      endereco: "Rua Raul Emrich, 59",
      cidade: "Bom Jardim - RJ"
    },
    {
      escola: "Colégio Estadual Lia Márcia Gonçalves Panaro",
      endereco: "Avenida Brasil, s/nº",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "Colégio Estadual Lions Club",
      endereco: "Rua General Augusto Sevilha, s/nº",
      cidade: "Teresópolis - RJ"
    },
    {
      escola: "Colégio Estadual Lourença Guimarães",
      endereco: "Rua José Gonçalves Brandão, 247",
      cidade: "Aperibé - RJ"
    },
    {
      escola: "Colégio Estadual Luiz de Camões",
      endereco: "Estrada do Barro Vermelho, 1446",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Luiz Gonzaga de Mattos",
      endereco: "Rua Rosa Sverberi, 253",
      cidade: "Barra Mansa - RJ"
    },
    {
      escola: "Colégio Estadual Luiz Reid",
      endereco: "Rua Teixeira de Gouveia, 942",
      cidade: "Macaé - RJ"
    },
    {
      escola: "Colégio Estadual Machado de Assis",
      endereco: "Rua Desembargador Lima Castro, 97",
      cidade: "Niterói - RJ"
    },
    {
      escola: "Colégio Estadual Madre Teresa de Calcutá",
      endereco: "General Conrobert Pereira da Costa, 228",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Marechal João Baptista de Mattos",
      endereco: "Avenida Brasil, 19644",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Marechal Rondon - Duque de Caxias",
      endereco: "Rua Rio Grande do Sul, n° 0",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "Colégio Estadual Marechal Rondon - Rio de Janeiro",
      endereco: "Rua Claudino Barata, 1267",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Marechal Souza Dantas",
      endereco: "Praça Marechal José Pessoa, 20",
      cidade: "Resende - RJ"
    },
    {
      escola: "Colégio Estadual Maria Izabel do Couto Brandão",
      endereco: "Avenida Alencastro Guimarães, 172",
      cidade: "Itaguaí - RJ"
    },
    {
      escola: "Colégio Estadual Maria Pereira Das Neves",
      endereco: "Travessa Doutor Leitão, s/nº",
      cidade: "Niterói - RJ"
    },
    {
      escola: "Colégio Estadual Marieta Cunha da Silva",
      endereco: "Rua Doutor Augusto Figueiredo, 983",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Mário Tamborindeguy",
      endereco: "Rodovia Amaral Peixoto km 6,5",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "Colégio Estadual Matemático Joaquim Gomes de Sousa - Intercultural Brasil - China",
      endereco: "Avenida Prefeito Sílvio Picanço, s/nº",
      cidade: "Niterói - RJ"
    },
    {
      escola: "Colégio Estadual Mato Grosso",
      endereco: "Avenida Brás de Pina, 1744",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Mauá",
      endereco: "Rodovia Amaral Peixoto, km 16",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "Colégio Estadual Milton Campos",
      endereco: "Rua dos Banguenses, s/n°",
      cidade: "Rio de Janeiro"
    },
    {
      escola: "Colégio Estadual Minas Gerais",
      endereco: "Avenida Jornalista Moacyr Padilha, s/nº",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "Colégio Estadual Ministro José de Moura e Silva",
      endereco: "Rua Moreira Façanha, s/n°",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "Colégio Estadual Ministro Marcos Freire",
      endereco: "Rua José Fernandes, n° 0",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Ministro Orozimbo Nonato",
      endereco: "Rua Darke de Matos, 152",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Missionário Mário Way",
      endereco: "Avenida Cesário de Melo, 6851",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Moacyr Padilha",
      endereco: "Rua General Gomes de Castro, 30",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Monsenhor Francisco",
      endereco: "Avenida Marechal Castelo Branco, 903",
      cidade: "Paraíba do Sul - RJ"
    },
    {
      escola: "Colégio Estadual Montebello Bondim",
      endereco: "Rua São Paulo, 91",
      cidade: "Mangaratiba - RJ"
    },
    {
      escola: "Colégio Estadual Monteiro Lobato - Duque de Caxias",
      endereco: "Rua Edir Barbosa, s/nº",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "Colégio Estadual Monteiro Lobato - Rio de Janeiro",
      endereco: "Estrada do Mato Alto, s/n°",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Mullulo da Veiga",
      endereco: "Rua Vereador José Vicente Sobrinho, 505",
      cidade: "Niterói - RJ"
    },
    {
      escola: "Colégio Estadual Nicarágua",
      endereco: "Rua Ribeiro de Andrade, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Nilo Peçanha - Campos dos Goytacazes",
      endereco: "Rua Lacerda sobrinho, 119",
      cidade: "Campos dos Goytacazes - RJ"
    },
    {
      escola: "Colégio Estadual Nilo Peçanha - São Gonçalo",
      endereco: "Rua Coronel Serrado, 1750",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "Colégio Estadual Nobu Yamagata",
      endereco: "Estrada do Boqueirão, 2783",
      cidade: "São Pedro da Aldeia - RJ"
    },
    {
      escola: "Colégio Estadual Nuta Bartlet James",
      endereco: "Avenida Senador Salgado Filho, s/nº",
      cidade: "Nilópolis - RJ"
    },
    {
      escola: "Colégio Estadual Olavo Bilac",
      endereco: "Avenida Presidente Vargas, 397",
      cidade: "Resende - RJ"
    },
    {
      escola: "Colégio Estadual Olavo Josino de Salles",
      endereco: "Rua Guarapuava, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Olga Benário Prestes",
      endereco: "Rua Joana Fontoura, 20",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Oliveira Botelho",
      endereco: "Rua General Ernesto de Oliveira, 54",
      cidade: "Resende - RJ"
    },
    {
      escola: "Colégio Estadual Oscar Batista",
      endereco: "Praça São Sebastião",
      cidade: "Cambuci - RJ"
    },
    {
      escola: "Colégio Estadual Oscar de Macedo Soares",
      endereco: "Avenida Saquarema, 381",
      cidade: "Saquarema - RJ"
    },
    {
      escola: "Colégio Estadual Oswaldo Cruz",
      endereco: "Estrada Real de Mauá, 2015",
      cidade: "Magé - RJ"
    },
    {
      escola: "Colégio Estadual Padre Franca",
      endereco: "Estrada Vereador Eugênio Guilherme Spitz, km 5",
      cidade: "Nova Friburgo - RJ"
    },
    {
      escola: "Colégio Estadual Padre Mello",
      endereco: "Avenida Governador Roberto Silveira, 578",
      cidade: "Bom Jesus do Itabapoana - RJ"
    },
    {
      escola: "Colégio Estadual Parada Angélica",
      endereco: "Avenida Fernando Figueiredo, 8",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "Colégio Estadual Paraíba",
      endereco: "Estrada Marechal Alencastro, 4035",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Parque Amorim",
      endereco: "Rua do Carmo, s/nº",
      cidade: "Belford Roxo - RJ"
    },
    {
      escola: "Colégio Estadual Patrono Duque de Caxias",
      endereco: "Avenida A, s/nº",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "Colégio Estadual Paulo de Frontin",
      endereco: "Rua Barão de Ubá, 399",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Paulo Freire",
      endereco: "Rua Americana, 164",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Pedro Braile Neto",
      endereco: "Rua Augusto Xavier de Lima, 55",
      cidade: "Resende - RJ"
    },
    {
      escola: "Colégio Estadual Pedro Soares",
      endereco: "Rua Pedro Soares, s/nº",
      cidade: "Angra dos Reis - RJ"
    },
    {
      escola: "Colégio Estadual Pestalozzi",
      endereco: "Rua Felipe Cardoso, 750",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Pierre Plancher",
      endereco: "Rua Abel de Alvarenga, s/nº",
      cidade: "Mesquita - RJ"
    },
    {
      escola: "Colégio Estadual Pinto Lima",
      endereco: "Rua São João, 127.",
      cidade: "Niterói - RJ"
    },
    {
      escola: "Colégio Estadual Piranema",
      endereco: "Rua Doze, s/nº",
      cidade: "Itaguaí - RJ"
    },
    {
      escola: "Colégio Estadual Poeta Mário Quintana",
      endereco: "Rua Inácio Serra,  s/nº",
      cidade: "Mesquita - RJ"
    },
    {
      escola: "Colégio Estadual Praia do Siqueira",
      endereco: "Rua Luiz Feliciano Cardoso, s/nº",
      cidade: "Cabo Frio - RJ"
    },
    {
      escola: "Colégio Estadual Prefeito Fontes Torres",
      endereco: "Rua 750, 235",
      cidade: "Volta Redonda - RJ"
    },
    {
      escola: "Colégio Estadual Prefeito Mendes de Moraes",
      endereco: "Rua Pio Dutra, 353",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Presidente Castelo Branco",
      endereco: "Rua Porto Alegre, 105",
      cidade: "Mesquita - RJ"
    },
    {
      escola: "Colégio Estadual Presidente Dutra",
      endereco: "Rodovia BR 465, km 47",
      cidade: "Seropédica - RJ"
    },
    {
      escola: "Colégio Estadual Presidente Kennedy - CEPK",
      endereco: "Rua Postal, 50",
      cidade: "Belford Roxo - RJ"
    },
    {
      escola: "Colégio Estadual Princesa Isabel",
      endereco: "Rua General Rondom, s/nº",
      cidade: "Petrópolis - RJ"
    },
    {
      escola: "Colégio Estadual Professor Alfredo Balthazar da Silveira",
      endereco: "Rua Guarani, s/nº",
      cidade: "Magé - RJ"
    },
    {
      escola: "Colégio Estadual Professor Aurélio Duarte - CEPAD",
      endereco: "Avenida Mário Mesquita,147",
      cidade: "Carmo - RJ"
    },
    {
      escola: "Colégio Estadual Professor Clóvis Monteiro",
      endereco: "Avenida dos Democráticos, 271",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Professor Dyrceu Rodrigues da Costa",
      endereco: "Avenida Santos Dumont, 162",
      cidade: "Rio Bonito - RJ"
    },
    {
      escola: "Colégio Estadual Professor Fernando Antônio Raja Gabaglia",
      endereco: "Rua General Cordolino de Azevedo, 110",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Professor Fonseca",
      endereco: "Estrada do Morro Grande, s/nº",
      cidade: "Araruama - RJ"
    },
    {
      escola: "Colégio Estadual Professor Horacio Macedo",
      endereco: "Rua Miguel Ângelo, 96",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Professor João Borges de Moraes",
      endereco: "Rua Doutor Teixeira Ribeiro, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Professor José Accioli",
      endereco: "Rua Costa Filho, 500",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Professor José Antônio Maia Vinagre",
      endereco: "Avenida Vera Cruz, 58",
      cidade: "Barra do Piraí - RJ"
    },
    {
      escola: "Colégio Estadual Professor José de Souza Marques",
      endereco: "Estrada do Quitungo 551",
      cidade: "Rio de Janeiro  - RJ"
    },
    {
      escola: "Colégio Estadual Professor Ozeas Gomes Larangeiras",
      endereco: "Estrada Vítor Dumas, 406",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Professor Renato Azevedo",
      endereco: "Avenida América Central, s/nº",
      cidade: "Cabo Frio - RJ"
    },
    {
      escola: "Colégio Estadual Professor Roberto Lyra",
      endereco: "Rua Niterói , s/nº",
      cidade: "Seropédica - RJ"
    },
    {
      escola: "Colégio Estadual Professor Souza",
      endereco: "Rua Nossa Senhora  Aparecida, s/nº",
      cidade: "Casimiro de Abreu - RJ"
    },
    {
      escola: "Colégio Estadual Professor Ubiratan Reis Barbosa",
      endereco: "Estrada Doutor Manoel Reis, 1598",
      cidade: "Nilópolis - RJ"
    },
    {
      escola: "Colégio Estadual Professor Vieira Fazenda",
      endereco: "Estrada da Barra de Guaratiba, 9140",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Professora Alcina Rodrigues de Lima",
      endereco: "Estrada Francisco da Cruz Nunes, s/nº",
      cidade: "Niterói - RJ"
    },
    {
      escola: "Colégio Estadual Professora Alda Bernardo dos Santos Tavares",
      endereco: "Rua Brasil, 153",
      cidade: "Magé - RJ"
    },
    {
      escola: "Colégio Estadual Professora Alvina Valério da Silva",
      endereco: "Rua Benedito Norberto Pupo, 91",
      cidade: "Guapimirim - RJ"
    },
    {
      escola: "Colégio Estadual Professora Alzira dos Santos da Silva",
      endereco: "Rua Doutor José de Carvalho, s/nº",
      cidade: "São João de Meriti - RJ"
    },
    {
      escola: "Colégio Estadual Professora Antonieta Palmeira",
      endereco: "Avenida José Mendonça de Campos, s/nº",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "Colégio Estadual Professora Clarice Coelho Moreira Caldas",
      endereco: "Rua Prefeito Antônio Raposo, s/nº",
      cidade: "Araruama - RJ"
    },
    {
      escola: "Colégio Estadual Professora Dalila de Oliveira Costa",
      endereco: "Rua Olívia Lopes, s/nº",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "Colégio Estadual Professora Eliana de Almeida Santos",
      endereco: "Rua Mato Grosso, s/nº",
      cidade: "Itaguaí - RJ"
    },
    {
      escola: "Colégio Estadual Professora Jeannette de Souza Coelho Mannarino",
      endereco: "Rua Olinda Ellis, 45",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Professora Maria Nazareth Cavalcanti Silva",
      endereco: "Rua Barbosa, 229",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Professora Maria Terezinha de Carvalho Machado",
      endereco: "Rua Cândido Benício, 826",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Professora Minervina Barbosa de Castro",
      endereco: "Avenida Duque de Caxias, 153",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "Colégio Estadual Professora Regina Célia dos Reis Oliveira",
      endereco: "Rua Maria José, s/nº",
      cidade: "São João de Meriti - RJ"
    },
    {
      escola: "Colégio Estadual Professora Ruth Taldo França",
      endereco: "Rua Duque de Caxias, 65",
      cidade: "Magé - RJ"
    },
    {
      escola: "Colégio Estadual Professora Sônia do Amaral Torres",
      endereco: "Rua Rosário Mercante, 15",
      cidade: "Miracema - RJ"
    },
    {
      escola: "Colégio Estadual Professora Sônia Maria Piedade de Moura",
      endereco: "Rua Francelina Ullmann, 1003",
      cidade: "Magé - RJ"
    },
    {
      escola: "Colégio Estadual Professora Vânia do Amaral Matias Edde",
      endereco: "Rua Fernanda, 1055",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Professora Venina Correa Torres",
      endereco: "Rua Elías Mitre, 21",
      cidade: "Nova Iguaçu - RJ"
    },
    {
      escola: "Colégio Estadual Rainha Vitória",
      endereco: "Rua Almerinda de Castro, 191",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Ramiz Galvão",
      endereco: "Praça Ricardo Gonçalves, 46",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual República de Angola",
      endereco: "Rua Santa Engrácia, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual República de Guiné-Bissau",
      endereco: "Rua Poço Central, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual República de Moçambique",
      endereco: "Praça do Trabalhador, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual República do Peru",
      endereco: "Rua Arquias Cordeiro, 508",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual República Italiana",
      endereco: "Avenida Dom Pedro II, 753",
      cidade: "Porto Real - RJ"
    },
    {
      escola: "Colégio Estadual Rio de Areia",
      endereco: "Rua Manoel Apolinário dos Santos, 8",
      cidade: "Saquarema - RJ"
    },
    {
      escola: "Colégio Estadual Rio de Janeiro",
      endereco: "Rua Sessenta e Dois, 777",
      cidade: "Volta Redonda - RJ"
    },
    {
      escola: "Colégio Estadual Rio Grande do Sul",
      endereco: "Rua Cento e Cinquenta e Quatro, 255",
      cidade: "Volta Redonda - RJ"
    },
    {
      escola: "Colégio Estadual Roberto Coelho Pedroso",
      endereco: "Rua Rui Barbosa, 350",
      cidade: "Três Rios - RJ"
    },
    {
      escola: "Colégio Estadual Rodrigo Otávio Filho Brasil - Itália",
      endereco: "Avenida Monsenhor Félix, 87",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Rondônia",
      endereco: "Rua Dourados, 155",
      cidade: "Volta Redonda - RJ"
    },
    {
      escola: "Colégio Estadual Rosária Trotta",
      endereco: "Rua Coronel Aníbal de Andrade, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Roselandia",
      endereco: "Rua Rui de Andrade Pinto, 115",
      cidade: "Barra Mansa - RJ"
    },
    {
      escola: "Colégio Estadual Rubens Farrulla",
      endereco: "Rua Plácido Figueiredo Junior, s/nº",
      cidade: "São João de Meriti - RJ"
    },
    {
      escola: "Colégio Estadual Rui Barbosa - Duque de Caxias",
      endereco: "Rua Primeiro de Maio, s/nº",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "Colégio Estadual Rui Barbosa - Petrópolis",
      endereco: "Rua Alynthor Werneck, 65",
      cidade: "Petrópolis - RJ"
    },
    {
      escola: "Colégio Estadual Rui Guimarães de Almeida",
      endereco: "Rua José de Alencar Leite, 81",
      cidade: "Santo Antônio de Pádua - RJ"
    },
    {
      escola: "Colégio Estadual Sacra Família do Tingua",
      endereco: "Rua Prefeito Cornélio, 100",
      cidade: "Engenheiro Paulo de Frontin - RJ"
    },
    {
      escola: "Colégio Estadual Santa Maria",
      endereco: "Praça das Primaveras, 60",
      cidade: "Casimiro de Abreu - RJ"
    },
    {
      escola: "Colégio Estadual Santa Tereza",
      endereco: "Estrada Tibiriça, s/nº",
      cidade: "Belford Roxo - RJ"
    },
    {
      escola: "Colégio Estadual São Bernardo",
      endereco: "Rua Bittencourt Sampaio, s/nº",
      cidade: "Belford Roxo - RJ"
    },
    {
      escola: "Colégio Estadual São Cristóvão",
      endereco: "Rua das Borboletas, s/nº",
      cidade: "Queimados - RJ"
    },
    {
      escola: "Colégio Estadual São Francisco de Paula",
      endereco: "Avenida Vereador Edenites da Silva Viana, 245",
      cidade: "São Francisco de Itabapoana - RJ"
    },
    {
      escola: "Colégio Estadual São Jorge",
      endereco: "Rua XV de Novembro, 100",
      cidade: "Nova Iguaçu - RJ"
    },
    {
      escola: "Colégio Estadual Sargento Wolff",
      endereco: "Rua Guarujá, s/nº",
      cidade: "Belford Roxo - RJ"
    },
    {
      escola: "Colégio Estadual Senador Sá Tinoco",
      endereco: "Avenida Senador Sá Tinoco, s/nº",
      cidade: "Itaperuna - RJ"
    },
    {
      escola: "Colégio Estadual Stuart Edgar Angel Jones",
      endereco: "Avenida de Santa Cruz, 8040",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Tenente General Napion",
      endereco: "Avenida Almirante Frontin, 50",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Thomaz Gomes",
      endereco: "Rua Silva Drumond, 97",
      cidade: "Carapebus - RJ"
    },
    {
      escola: "Colégio Estadual Vicente Jannuzzi",
      endereco: "Avenida das Américas, 6120",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Vila Bela",
      endereco: "Rua Tibiriça, 285",
      cidade: "Mesquita - RJ"
    },
    {
      escola: "Colégio Estadual Vila Guarani",
      endereco: "Rua Manoel Fontoura S/N",
      cidade: "São Gonçalo"
    },
    {
      escola: "Colégio Estadual Vila Maria",
      endereco: "Rua Antônio Graciano da Rocha, 1001",
      cidade: "Barra Mansa - RJ"
    },
    {
      escola: "Colégio Estadual Vilma Atanázio",
      endereco: "Rua Jaguaruna, 157",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Vinícius de Moraes",
      endereco: "Praça Paulo Biar, s/nº",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "Colégio Estadual Viriato Correa",
      endereco: "Rua Américo da Rocha, 1596",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Colégio Estadual Visconde de Cairu",
      endereco: "Rua Bento Siqueira, s/nº",
      cidade: "São João de Meriti - RJ"
    },
    {
      escola: "Colégio Estadual Visconde de Itaboraí",
      endereco: "Rua Doutor Macedo, 334",
      cidade: "Itaboraí - RJ"
    },
    {
      escola: "Colégio Estadual Visconde do Rio Branco",
      endereco: "Rua Riachuelo, 102",
      cidade: "Campos dos Goytacazes - RJ"
    },
    {
      escola: "Colégio Estadual Waldemiro Pitta",
      endereco: "Rua Coronel Sérgio Pitta, 400",
      cidade: "Cambuci - RJ"
    },
    {
      escola: "Colégio Estadual Zumbi dos Palmares",
      endereco: "Rua Thomaz Antônio Gonzaga, 1845",
      cidade: "Duque de Caxias - RJ"
    },
    {
      escola: "Colégio Estafual Nephtalina Carvalho Avila",
      endereco: "Rua Olier Freitas da Silveira, 301",
      cidade: "Rio das Flores - RJ"
    },
    {
      escola: "Colégio NATA",
      endereco: "Rua Capitão Juvenal Figueiredo, s/nº",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "DiversiGames",
      endereco: "Rua Marechal Aguiar 72",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Estadual Aspino Rocha",
      endereco: "Rua José Rodrigues Póvoas, 561",
      cidade: "Cabo Frio - RJ"
    },
    {
      escola: "Escola Estadual Coronel José Rosa da Silva",
      endereco: "Estrada Itaperuna via Barreiros, s/nº",
      cidade: "Natividade - RJ"
    },
    {
      escola: "Escola Estadual Cruzeiro do Sul",
      endereco: "Rua Capitão Múcio Levi, 120",
      cidade: "São Gonçalo - RJ"
    },
    {
      escola: "Escola estadual Gandhi",
      endereco: "Rua Nilton de Souza Filho s/n",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Estadual Professor Gonçalves",
      endereco: "Rua Campo Maior, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Estadual São Domingos Sávio",
      endereco: "Rua Doutor Martins Torres, 223",
      cidade: "Niterói - RJ"
    },
    {
      escola: "Escola Municipal Abrahão Jabour",
      endereco: "Rua Raul Azevedo, 378",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Alberto José Sampaio",
      endereco: "Rua Inhumay, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Aventina Maria Ferreira",
      endereco: "Rodovia Afonso Celso Celso, s/nº",
      cidade: "São Francisco do Itabapoana - RJ"
    },
    {
      escola: "Escola Municipal Benevenuta Ribeiro",
      endereco: "Rua Felipo Cavalcanti, 10",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Coronel Corsino do Amarante",
      endereco: "Rua do Imperador, 62",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Eider Ribeiro Dantas",
      endereco: "Rua Manoel Araújo dos Santos, s/nº",
      cidade: "Itaguaí - RJ"
    },
    {
      escola: "Escola Municipal Estados Unidos",
      endereco: "Rua Itapiru, 453",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Evanir da Silva Gago",
      endereco: "Rua Oito de Maio, 163",
      cidade: "Magé - RJ"
    },
    {
      escola: "Escola Municipal França",
      endereco: "Rua Padre Manoel da Nóbrega, 725",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Geo Félix Mielli Venerando",
      endereco: "Rua Carlos Seixas s/n°",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Guimarães Rosa",
      endereco: "Rua Rio Dourado, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Gurgel do Amaral",
      endereco: "Rua Gustavo Augusto de Resende, 541",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Helton Alvares Veloso de Castro",
      endereco: "Rua Projetada A, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Jorge Zarur",
      endereco: "Rua Paulino do Sacramento, 1353",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Jornalista e Escritor Daniel Piza",
      endereco: "Avenida Prefeito Sá Lessa, 300",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal José Eulálio de Andrade",
      endereco: "Alameda Antônio da Luz Fernandes, 10",
      cidade: "Paty do Alferes - RJ"
    },
    {
      escola: "Escola Municipal Levy Miranda",
      endereco: "Rua Iguaba Grande, 313",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Liberdade",
      endereco: "Avenida João XXIII, Rua 13, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Maestro Pixinguinha",
      endereco: "Rua Anambês, 50",
      cidade: "Río de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Malba Tahan",
      endereco: "Avenida Brasil, 17221",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Marechal Pedro Cavalcanti",
      endereco: "Rua Porto Firme, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Mário Paulo de Brito",
      endereco: "Rua São Leonardo, 51",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Miguel de Oliveira",
      endereco: "Rua 5, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Milton Campos",
      endereco: "Rua dos Banguenses, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Monsenhor Rocha",
      endereco: "Avenida Nossa Senhora da Penha, 589",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal O'higgins",
      endereco: "Rua Bombaim, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Orestes Barbosa",
      endereco: "Rua Eduardo Souto, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Oswaldo Teixeira",
      endereco: "Rua Milão, 95",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Padre Francisco Peres Blasco-Padre Paco",
      endereco: "Rua Tancredo Mendes Paixão, 789",
      cidade: "Casimiro de Abreu - RJ"
    },
    {
      escola: "Escola Municipal Prefeito Juarez Antunes",
      endereco: "Rua Amsterdã, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Presidente Médici",
      endereco: "RuaTibagi, 316",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Professor Ivan Rocco Marchi",
      endereco: "Av Brasil, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Santa Luzia",
      endereco: "Rua Nossa Senhora Aparecida, s/nº",
      cidade: "Casimiro de Abreu - RJ"
    },
    {
      escola: "Escola Municipal Santo Expedito",
      endereco: "Estrada Olegário Dias, 1685",
      cidade: "Queimados - RJ"
    },
    {
      escola: "Escola Municipal Terra de Educar",
      endereco: "Estrada da Floresta, 2808",
      cidade: "Paracambi - RJ"
    },
    {
      escola: "Escola Municipal Thomé de Souza",
      endereco: "Rua Yalta, 60",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Visconde de Porto Seguro",
      endereco: "Rua Teófilo Guimarães, 604",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Escola Municipal Zélia Braune",
      endereco: "Rua George Bizet, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Fundação Darcy Vargas",
      endereco: "Rua do Livramento, 27",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GEO Fernando de Azevedo",
      endereco: "Rua das Palmeiras Imperiais, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GEO Isabel Salgado",
      endereco: "Parque Olímpico - Rua Denise Dobbin Bauerfeldt, Portão 06",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Almirante Tamandaré",
      endereco: "Avenida Presidente João Goulart, 296",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Alzira Araújo",
      endereco: "Rua Ary Lôbo, 430",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Bento Ribeiro",
      endereco: "Rua Cônego Tobias, 112",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Brigadeiro Faria Lima",
      endereco: "Rua Violeta, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Cardeal Leme",
      endereco: "Rua Ébano, 205",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Dulce Araújo - Tia Dulce",
      endereco: "Rua Inglaterra, 132",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Fornovo",
      endereco: "Rua Gentil de Almeida, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Isaías Alves",
      endereco: "Rua Dom José de Souza, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Luiz Cézar Sayao Garcez",
      endereco: "Rua Paranapanema, 1055",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Luiz Paulo Horta",
      endereco: "Estrada da Gávea, 522",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Medalhista Olímpica Mayra Aguiar da Silva",
      endereco: "Rua Frei Timótheo, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Mestre Diego Braga",
      endereco: "Rua Velha, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Montese",
      endereco: "Rua Mar Grande, 315",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Osmar Paiva Camelo",
      endereco: "Rua Teixeira Ribeiro, 1000",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Otelo de Souza Reis",
      endereco: "Avenida Antares, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Pedro Ernesto",
      endereco: "Avenida Professor Abelardo Lobo, 5",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Professor Ari Marques Pontes",
      endereco: "Rua Giordano Vincenzo, 500",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Professor Carneiro Felipe",
      endereco: "Rua Juriari, 238",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Professor Lourenço Filho",
      endereco: "Praça Edmundo Rêgo, 11",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Professora Eulália Rodrigues de Oliveira Vieira",
      endereco: "Rua Prado Júnior, 662",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Professora Jurema Peçanha Giraud",
      endereco: "Rua Atacama s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Rainha Vitória",
      endereco: "Rua Benedito Lacerda, s/nº",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET República de El Salvador",
      endereco: "Rual Almeida Nogueira, 85",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Roberto Civita",
      endereco: "Estrada dos Palmares, 4603",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Roberto Morena",
      endereco: "Avenida Celsário de Melo, 11485",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Venezuela",
      endereco: "Rua Amaral Costa 37",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "GET Yolis da Silva",
      endereco: "Avenida Cesário de Melo, 7805",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Instituto da Criança",
      endereco: "Rua Faro, 80, 2º andar",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Instituto de Educação Carmela Dutra",
      endereco: "Avenida Ministro Edgard Romero, 491",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Instituto de Educação de Nova Friburgo",
      endereco: "Praça Dermeval Barbosa Moreira, 15",
      cidade: "Nova Friburgo - RJ"
    },
    {
      escola: "Instituto de Educação Eliana Duarte da Silva Breijão",
      endereco: "Rua Deputado Luiz Fernando Linhares, 795",
      cidade: "Porciúncula - RJ"
    },
    {
      escola: "Instituto de Educação Sarah Kubitschek - IESK",
      endereco: "Avenida Manuel Caldeira de Alvarenga, 1203",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Instituto de Educação Thiago Costa",
      endereco: "Rua Abreu César, 76",
      cidade: "Vassouras - RJ"
    },
    {
      escola: "Instituto Futuro Bom",
      endereco: "Avenida Epitácio Pessoa, 1007",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Instituto Quatro Estações",
      endereco: "Avenida Brasil 33.000 bloco 8 306",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Instituto Reação",
      endereco: "Rua Bertha Lutz, 84",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Instituto Superior de Educação Professor Aldo Muylaert",
      endereco: "Avenida Deputado Alair Ferreira, 37",
      cidade: "Campos dos Goytacazes - RJ"
    },
    {
      escola: "Liceu de Humanidades de Campos",
      endereco: "Praça Barão do Rio Branco, 15",
      cidade: "Campos dos Goytacazes - RJ"
    },
    {
      escola: "Núcleo de Arte Silveira Sampaio",
      endereco: "Rua José Perrota, 31",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Senac - Riachuelo",
      endereco: "Rua Vinte e Quatro de Maio, 543",
      cidade: "Rio de Janeiro - RJ"
    },
    {
      escola: "Sim! Eu Sou do Meio",
      endereco: "Rua Gonçalves Gato, 288",
      cidade: "Belford Roxo - RJ"
    },
    {
      escola: "Terceiro Colégio da Policia Militar",
      endereco: "Rua Piracicaba, s/nº",
      cidade: "Duque de Caxias - RJ"
    }
  ];
};

module.exports = escolas();
