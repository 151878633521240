import Vue from "vue";
import VueRouter from "vue-router";
import FAQview from "../views/FAQ.vue";
import Sorteio from "../views/Sorteio.vue"
import ContatoView from "../views/Contato.vue";
import Ranking from "../views/Ranking.vue";
import GanhadoresView from "@/views/Ganhadores.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "faq",
    component: FAQview,
  },
  {
    path: "/ganhadores",
    name: "ganhadores",
    component: GanhadoresView,
  },
  {
    path: "/sorteioRockinRio",
    name: "sorteio",
    component: Sorteio,
  },
  {
    path: "/contato",
    name: "contato",
    component: ContatoView,
  },
  {
    path: "/ranking",
    name: "ranking",
    component: Ranking,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
