<template>
  <div class="page faq">

    <v-row class="hide_desktop">
      <v-col cols="12" class="mt-12 hide_desktop">
        <h1 class="faq-title hide_desktop">Olá! Nesta página você pode esclarecer todas as suas dúvidas sobre a
          Olímpiada Digital Senac 2024. <br> <br> Aqui você encontrará informações sobre o regulamento, as regras do
          jogo, o
          acompanhamento do ranking em tempo real, detalhes sobre a premiação e muito mais!</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="hide_mobile">
        <v-img src="../assets/plus.png" lg="1" md="1" class="hide_mobile" height="100" contain></v-img>
      </v-col>

      <v-col cols="12" lg="7" md="12" sm="12" class="pt-0">
        <h1 class="faq-title hide_mobile">Olá! Nesta página você pode esclarecer todas as suas dúvidas sobre a Olímpiada
          Digital Senac 2024. <br> <br> Aqui você encontrará informações sobre o regulamento, as regras do jogo, o
          acompanhamento
          do ranking em tempo real, detalhes sobre a premiação e muito mais! </h1>

      </v-col>

      <v-col class="hide_mobile">
        <v-img src="../assets/double-triangle.png" lg="1" md="1" class="hide_mobile" height="100" contain></v-img>
      </v-col>

      <v-col cols="12" xl="5" lg="5" md="5" sm="5" class="hide_mobile">
        <v-img src="../assets/cloud.png" max-height="300" contain class="cloud_img"></v-img>
      </v-col>
    </v-row>

    <v-row class="ml-16 mr-16">
      <div class="faq-container">
        <div class="flex-right mb-8">
          <v-text-field class="search-input" v-model="filter" label="Qual a sua dúvida?" outlined rounded dark
            prepend-inner-icon="mdi-magnify" hide-details="auto"></v-text-field>
        </div>

        <v-expansion-panels accordion flat dark>
          <v-expansion-panel v-if="filteredItems.length > 0" class="faq-panel" cols="12"
            v-for="(item, index) in filteredItems" :key="index">
            <v-expansion-panel-header class="faq-question">
              {{ item.pergunta }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="faq-explication">
              {{ item.resposta }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>


        <div class="ma-10" v-if="filteredItems.length == 0">
          <h3>Desculpe, tente usar palavras-chave sobre sua dúvida</h3>
        </div>

      </div>
    </v-row>

    <!-- Footer -->
    <Footer></Footer>

  </div>
</template>

<script>
import perguntas from "@/lib/Perguntas";
import Footer from '../components/footer.vue'

export default {
  name: 'FAQview',
  components: {
    Footer
  },
  data: () => ({
    perguntas: [],
    filter: '',
    loading: false
  }),
  mounted() {
    perguntas
    this.perguntas = perguntas;
    window.scrollTo(0, 0);
  },
  computed: {
    filteredItems() {
      return perguntas.filter(p => p.pergunta.toLocaleLowerCase().includes(this.filter.toLocaleLowerCase()));
    }
  },
}
</script>

<style scoped>
.faq {
  background-color: #5B268E;
  min-height: 100vh;
  color: white;
  background-image:
    radial-gradient(closest-corner circle at 80% 30%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%),
    radial-gradient(closest-corner circle at 10% 15%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%),
    radial-gradient(closest-corner circle at 5% 70%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%);
}


.faq-title {
  text-align: start;
  font-size: 25px;
}

.cloud_img {
  position: absolute;
  right: 0;
}

.faq-division {
  padding-top: 60px;
}

.faq-container {
  width: 70%;
  margin: auto;
}

.faq-panel {
  background-color: transparent !important;
  border-top: 1px solid #FFFFFF;
}

.faq-question {
  font-size: 18px;
}

.faq-explication {
  text-align: start;
  font-size: 18px;
  font-weight: 325;
}

.final {
  border-bottom: 1px solid #FFFFFF
}

.v-expansion-panel-header--active {
  font-weight: bold;
}

@media screen and (max-width: 764px) {
  .faq-title {
    text-align: center;
    font-size: 18px;
  }

  .faq-division {
    padding-top: 0;
  }

  .escola {
    padding-top: 30px;
  }

  .faq-container {
    width: 90%;
  }
}
</style>
