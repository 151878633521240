<template>
  <div class="page contato">

    <!-- Form -->
    <v-row>
      <v-col cols="12" class="mt-12">
        <h1>Entre em Contato</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="hide_mobile">
        <v-img src="../assets/plus.png" lg="1" md="1" class="hide_mobile" height="100" contain></v-img>
      </v-col>

      <v-col cols="12" lg="7" md="7">
        <p>Tem alguma sugestão ou dúvida? Preencha o formulário abaixo e fale com a gente!</p>
      </v-col>

      <v-col class="hide_mobile">
        <v-img src="../assets/double-triangle.png" lg="1" md="1" class="hide_mobile" height="100" contain></v-img>
      </v-col>
    </v-row>

    <v-row class="form_player d-flex justify-end">
      <v-col cols="12" xl="5" lg="5" md="6" sm="8" class="pa-10">
        <v-form ref="form_player" v-model="valid">
          <v-text-field v-model="school" label="Nome da escola ou instituição" required filled
            background-color="rgba(255, 255, 255, 0.8)" color="#F66844"></v-text-field>
          <v-text-field v-model="name" label="Nome completo" required filled background-color="rgba(255, 255, 255, 0.8)"
            color="#F66844"></v-text-field>
          <v-text-field v-model="email" label="E-mail" required filled background-color="rgba(255, 255, 255, 0.8)"
            color="#F66844"></v-text-field>
          <v-textarea v-model="question" label="Texto" required filled background-color="rgba(255, 255, 255, 0.8)"
            color="#F66844"></v-textarea>


          <div class="d-flex align-start">
            <v-btn color="#F66844" @click="register" large class="text-none" style="color: #FFFFFF;" :loading="loading"
              :disabled="loading">
              Enviar
            </v-btn>
          </div>
        </v-form>
      </v-col>

      <v-col cols="12" xl="5" lg="5" md="5" sm="5" class=" justify-end hide_mobile">
        <v-img src="../assets/contato-img-cloud.png" max-height="400" contain class="contato_img"></v-img>
      </v-col>
    </v-row>

    <v-row class="d-flex align-end justify-end contato-cube">
      <v-col cols="6" lg="6" md="5" sm="5" xs="2">
        <v-img src="../assets/contato-img.png" contain></v-img>
      </v-col>
    </v-row>


    <!-- Footer -->
    <Footer></Footer>

  </div>
</template>

<script>
import { insertSheetItem } from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
import Footer from '../components/footer.vue'

export default {
  name: 'ContatoView',
  components: {
    Footer
  },
  data: () => ({
    valid: true,
    school: '',
    name: '',
    email: '',
    question: '',
    loading: false,
  }),
  mounted() {
    window.scrollTo(0, 0)
  },

  methods: {
    async register() {
      if (!this.school || !this.name || !this.email || !this.question) return emitToastr("error", "Você deve preencher todas as informações para fazer o pré cadastro.");

      const data = {
        spreadsheetId: "1gN4Cx5fMFHfBXVvzygywmqarIXoUuuigeMIa2SS-_3c",
        sheetName: "Contato Escolas",
        values: [[this.school, this.name, this.email, this.question]]
      }

      this.loading = true;
      const resp = await insertSheetItem(data);
      this.loading = false;
      if (resp && resp.data && resp.data.statusText == "OK") {
        emitToastr("success", "Mensagem enviada com sucesso. Aguarde informações em breve.");
        this.school = "";
        this.name = "";
        this.email = "";
        this.question = "";
      }
    }
  },
}
</script>

<style scoped>
.contato {
  background-color: #5B268E;
  min-height: 100vh;
  color: white;
  background-image:
    radial-gradient(closest-corner circle at 80% 30%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%),
    radial-gradient(closest-corner circle at 10% 15%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%),
    radial-gradient(closest-corner circle at 5% 70%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%);
}

.contato_img {
  position: absolute;
  right: 0;
}

.radio-label {
  color: white;
}

@media screen and (max-width: 1000px) {
  .contato-cube {
    margin-top: 100px !important;
  }

}
</style>
