const perguntas = () => {
  return [
    {
      pergunta: "Como funciona?",
      resposta: "A Olimpíada é uma forma interativa e gamificada de desvendar os assuntos mais recentes do mundo da tecnologia. Cada escola ou instituição social será uma equipe. Todos(as) os(as) usuários(as), no ato do cadastro, deverão escolher uma equipe para receber os pontos que conquistar ao longo do game. Dessa forma, escolas e instituições, promovem uma competição saudável em torno do conhecimento.",
    },
    {
      pergunta: "Como participar?",
      resposta: "Para participar, basta fazer o cadastro da instituição que você representa, aqui na página, e aguardar o e-mail de confirmação. A partir daí, é só preencher os dados solicitados e pronto: sua escola/instituição social já se torna uma equipe no game. No dia 10/06, a plataforma estará liberada para usuários(as) começarem a jogar.",
    },
    {
      pergunta: "Como funciona o cadastro do usuário(a)? ",
      resposta: "Para se cadastrar, todos(as) os(as) usuários(as) precisam acessar o link: https://desafiodigitalsenac.joco.com.br, escolher a equipe correspondente à sua escola ou instituição para receber os pontos que conquistarão ao longo do game. ",
    },
    {
      pergunta: "Quem pode participar?",
      resposta: "Toda escola e instituição social pública do Estado do Rio de Janeiro está apta a participar da Olimpíada. ",
    },
    {
      pergunta: "Até quando posso cadastrar minha escola/instituição social?",
      resposta: "O cadastro encerrou no dia 06 de junho de 2024. Até lá, não se preocupe que iremos informar por e-mail.",
    },
    {
      pergunta: "Não encontrei a minha escola ou instituição no cadastro, o que fazer? ",
      resposta: "Envie um e-mail para o endereço: olimpiadadigital@joco.com.br, para que possamos investigar e responder adequadamente.",
    },
    {
      pergunta: "Quantos usuários(as) da minha escola ou instituição estão cadastrados no game?",
      resposta: "Para saber quantos usuários (as) da sua escola ou instituição estão cadastrados no game envie um e-mail com o nome da sua escola e do responsável para o endereço: olimpiadadigital@joco.com.br.",
    },
    {
      pergunta: "Existe premiação para escolas e instituições sociais?",
      resposta: "Sim! As escolas e instituições sociais que se destacarem na Olimpíada Digital Senac 2024 serão recompensadas com uma variedade. As equipes que liderarem o ranking terão acesso a uma seleção diversificada de prêmios, desenhados para celebrar suas conquistas e estimular o contínuo envolvimento com a tecnologia. Além disso, mantenha-se em atenção, pois novos prêmios podem ser adicionados ao longo da competição, enriquecendo ainda mais a experiência e as recompensas para os vencedores. Esses prêmios são uma forma de reconhecer o esforço e o sucesso dos participantes, incentivando a excelência e a inovação contínuas.",
    },
    {
      pergunta: "Quais são os prêmios disponíveis? ",
      resposta: "Você pode conferir todos os prêmios disponíveis de acordo com a colocação da sua escola ou instituição na aba superior aqui do site em “Prêmios”.",
    },
    {
      pergunta: "Como funciona o prêmio do Rock In Rio?",
      resposta: "A premiação da Olimpíada Digital Senac é concedida diretamente às escolas vencedoras, que têm a responsabilidade de decidir sobre a melhor forma de distribuição dos prêmios entre os estudantes e participantes envolvidos. É importante destacar que, entre os prêmios oferecidos, estão ingressos para o Rock in Rio. No entanto, a escolha dos dias dos ingressos não está ao alcance dos ganhadores ou da escola, pois eles são distribuídos de maneira aleatória pela produção do evento. Dessa forma, a escola também decide como alocar os ingressos recebidos, dentro das possibilidades oferecidas.",
    },
    {
      pergunta: "Como funciona a distribuição dos prêmios?",
      resposta: "A distribuição dos prêmios da Olimpíada Digital Senac é organizada de forma a celebrar e reconhecer o sucesso das escolas e instituições sociais participantes. Os prêmios físicos são entregues pessoalmente às dez primeiras escolas no ranking durante uma cerimônia especial, no evento Senac Rio Summit, aproveitando a ocasião para premiar as equipes vencedoras e também para promover a interação entre os participantes. Quanto aos demais prêmios que não forem entregues no evento, o envio será organizado em coordenação direta com o responsável de cada escola ou instituição vencedora.O processo de entrega é combinado previamente, assegurando que todos os prêmios sejam recebidos de maneira segura e eficiente, respeitando as necessidades e preferências de cada instituição.Essa abordagem personalizada garante que cada grupo de vencedores receba suas recompensas de forma adequada e celebratória.",
    },
    {
      pergunta: "A Olimpíada é nacional?",
      resposta: "Por enquanto, não. Só estão habilitadas a participar as escolas e instituições sociais públicas do estado do Rio de Janeiro. Mas qualquer usuário pode participar como competidor, de qualquer lugar do Brasil. Para isso, basta escolher uma equipe para doar seus pontos. Caso você não tenha vínculo com nenhuma escola ou instituição social, selecione “Não tenho escola ou instituições”.",
    },
    {
      pergunta: "Qual o período da Olimpíada?",
      resposta: "A Olimpíada vai acontecer entre os dias 10 de junho de 2024 a 30 de agosto de 2024. O período de cadastro de escolas e instituições sociais encerrou no dia 06 de junho de 2024.",
    },
    {
      pergunta: "Quando e como serão liberados os resultados?",
      resposta: "Os resultados serão divulgados a partir do dia 04 de setembro de 2024. As dez primeiras equipes melhores colocadas e o usuário ganhador do laptop gamer terão seus nomes divulgados aqui na página e as demais instruções serão comunicadas via whatsapp. A promoção seguirá o regulamento divulgado.",
    },
    {
      pergunta: "Onde encontro informações sobre o Sorteio Rock In Rio?",
      resposta: "Para mais informações sobre o sorteio especial para o Dia Teste do Rock In Rio acesse o link: https://escolas.olimpiadadigitalsenac.com.br/sorteioRockinRio. Você encontrará todas as regras de participação, datas e os prêmios disponíveis."
    }
  ];
};

module.exports = perguntas();
