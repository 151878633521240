<template>
  <v-app>

    <nav class="d-flex align-center navbar">
      <v-img src="./assets/logo.png" max-width="200" height="100" contain></v-img>
      <div class="navbar_desktop ml-auto hide_mobile">
        <router-link to="/" class="mr-8">FAQ</router-link>
        <router-link to="/ganhadores" class="mr-8">Ganhadores</router-link>
        <router-link to="/sorteioRockinRio" class="mr-8">Sorteio Rock in Rio</router-link>
        <router-link to="/ranking" class="mr-8">Ranking das escolas e instituições</router-link>
        <a href="https://static-joco.sfo2.cdn.digitaloceanspaces.com/senac/pdfs/1724340264064.pdf" target="_blank"
          style="margin-right: 32px;">Prêmios</a>
        <a href="https://static-joco.sfo2.cdn.digitaloceanspaces.com/nossojoco/pdfs/1717762909221.pdf" target="_blank"
          style="margin-right: 32px;">Regulamento</a>
        <router-link to="/contato" target="_blank" class="mr-8">Contato</router-link>
      </div>

      <v-menu transition="slide-x-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="#FFFFFF" text v-bind="attrs" v-on="on" class="menu hide_desktop">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>

        <v-list style="background-color: #F66844;" class="text-left">
          <v-list-item-group active-class="menu-active" :value="menuIndex">
            <router-link to="/">
              <v-list-item link class="menu-item pa-2 px-4">
                FAQ
              </v-list-item>
            </router-link>

            <router-link to="/ganhadores">
              <v-list-item link class="menu-item pa-2 px-4">
                Ganhadores
              </v-list-item>
            </router-link>


            <router-link to="/sorteioRockinRio">
              <v-list-item link class="menu-item pa-2 px-4">
                Sorteio Rock in Rio
              </v-list-item>
            </router-link>

            <router-link to="/ranking">
              <v-list-item link class="menu-item pa-2 px-4">
                Ranking das escolas e instituições
              </v-list-item>
            </router-link>

            <a href="https://static-joco.sfo2.cdn.digitaloceanspaces.com/senac/pdfs/1724340264064.pdf" target="_blank">
              <v-list-item link class="menu-item pa-2 px-4">
                Prêmios
              </v-list-item>
            </a>


            <a href="https://static-joco.sfo2.cdn.digitaloceanspaces.com/nossojoco/pdfs/1717762909221.pdf"
              target="_blank">
              <v-list-item link class="menu-item pa-2 px-4">
                Regulamento
              </v-list-item>
            </a>

            <router-link to="/contato"><v-list-item class="menu-item pa-2 px-4">
                Contato
              </v-list-item>
            </router-link>

          </v-list-item-group>
        </v-list>
      </v-menu>

    </nav>
    <router-view />
  </v-app>
</template>



<script>
export default {
  data: () => {
    return {
      menuIndex: 0
    }
  },

  created() {
    this.menuIndex = 1;
    if (this.$route.path == '/') this.menuIndex = 0;
    if (this.$route.path == '/jogadores') this.menuIndex = 1;
    if (this.$route.path == '/escolas') this.menuIndex = 2;
    if (this.$route.path == '/contato') this.menuIndex = 3;
  }
}
</script>

<style>
@font-face {
  font-family: "GothamMedium";
  font-display: auto;
  font-style: normal;
  src: local("GothamMedium") url("./assets/fonts/GothamMedium.ttf");
}

#app {
  font-family: "GothamMedium", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #5B268E;
  min-height: 100vh;
}

body {
  margin: 0px;

}

nav {
  padding: 30px;
}

nav a,
.v-list-item-group a {
  font-weight: bold;
  color: #B5B5B5 !important;
  text-decoration: none;
}

nav a.router-link-exact-active {
  color: white !important;
}

.navbar {
  background-image: linear-gradient(to bottom, rgba(39, 18, 87, 1), rgba(91, 38, 142, 0.08));
}

.menu-item {
  color: white !important;
}

.menu-active {
  background-color: #15B8CE;
  color: #000000 !important;
}

.menu-active a {
  background-color: #15B8CE;
  color: #000000 !important;
}

.hide_desktop {
  display: none;
}

.row {
  margin: 0 !important;
}

.text {
  font-family: "GothamMedium", sans-serif;
}

.links_footer {
  align-items: start;
}

h1 {
  font-size: 40px;
}


.hero_title {
  font-size: 56px;
  line-height: 67px;
}

.v-card {
  border-radius: 8px;
}

.card_title {
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  margin-top: 10px;
  justify-content: center;
  color: #1C155C;
}

.footer {
  background-color: #1C155C;
  margin-top: 90px !important;
}

.tower-img {
  height: 550px;
  position: absolute;
  bottom: 30px;
  left: 0;
}

.door-img {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.joco {
  justify-content: end;
}

@media screen and (max-width: 1263px) {
  .footer_end_wrap {
    flex-direction: column-reverse;
  }

  .footer_end {
    justify-content: center;
  }

  .tower-img {
    height: 430px;
    left: -40px;
    bottom: 150px;
  }

  .door-img {
    height: 350px;
    right: -20px;
  }

}

@media screen and (max-width: 985px) {
  .hide_mobile {
    display: none;
  }

  .hide_desktop {
    display: unset;
  }

  .menu {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .hero_title {
    margin-top: 0;
    font-size: 32px;
    line-height: 38px;
  }

  .form_player {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .form_school {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .tower-mobile {
    position: absolute;
    height: 350px !important;
    left: -70px;
    bottom: 310px;
  }
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 24px;
  }

  .navbar {
    justify-content: center !important;
  }

  .hero {
    display: flex;
    flex-direction: column-reverse;
  }

  .form-text {
    text-align: start;
  }

  .links_footer {
    align-items: center;
  }


  .footer {
    height: 500px;
    padding-top: 100px;
  }

  .tower-mobile {
    left: -100px;
    bottom: 450px;
  }

  .footer_end {
    justify-content: center;
  }

  .hide_tiny_mobile {
    display: none;
  }

}
</style>
