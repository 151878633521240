import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueMask from "v-mask";
import VueGtag from 'vue-gtag'
import "@/assets/global.css";

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: {
    id: 'G-VPJZ18HSL2',
  }
}, router)

Vue.config.productionTip = false;
Vue.use(VueMask);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
